import Layout from '@/components/modules/Common/Layout';
import {
  InvoiceDashboard,
  InvoiceDetails,
  InvoiceSettings,
  InvoiceSettingsConfidenceScore,
  InvoiceSettingsWorkflowConfig
} from '@/pages';
import { useAppDataContext } from '@/context';
import { JSX } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

const Router = (): JSX.Element => {

  const {
    state: { appPath, appSettingsPath }
  } = useAppDataContext();

  return (
    <BrowserRouter basename={`/${appPath}`}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="dashboard" element={<InvoiceDashboard />} />
          <Route path="invoice" element={<InvoiceDetails />} />
          <Route path={appSettingsPath} element={<InvoiceSettings />} />
          <Route path={`${appSettingsPath}/confidence-score`} element={<InvoiceSettingsConfidenceScore />} />
          <Route path={`${appSettingsPath}/workflow-config`} element={<InvoiceSettingsWorkflowConfig />} />
          <Route path="" element={<Navigate to="/dashboard" />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
