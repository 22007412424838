import { Sorting } from '@/model/sorting';
import { useState } from 'react';

const nextSortingUtil = (sorting: Sorting): Sorting => {
  switch (sorting) {
    case Sorting.Ascending:
      return Sorting.Descending;
    case Sorting.Descending:
      return Sorting.Unsorted;
    default:
      return Sorting.Ascending;
  }
};

const useSorting = (initialSortOrder: Sorting = Sorting.Unsorted): [Sorting, () => void] => {
  const [sorting, setSorting] = useState<Sorting>(initialSortOrder);

  const nextSorting = (): void => {
    setSorting(nextSortingUtil(sorting));
  };

  return [sorting, nextSorting];
};

export { nextSortingUtil, useSorting };
