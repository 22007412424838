import IconClose from '@/assets/icons/icon-close.svg';
import IconSearch from '@/assets/icons/icon-search.svg';
import { SEARCH_TIMEOUT } from '@/config/ui.config';
import { useInvoiceDashboardContext } from '@/context';
import { useNonInitialEffect } from '@/hooks/useNonInitialEffect';
import { debounce } from '@/utils';
import { JSX, useEffect, useRef, useState } from 'react';
import './SearchBar.css';

const SearchBar = (): JSX.Element => {
  const searchTextRef = useRef<HTMLInputElement>(null);

  const {
    state: { invoicesPageFilter },
    actions: { setInvoicesPageFilter }
  } = useInvoiceDashboardContext();

  const [searchText, setSearchText] = useState<string>(invoicesPageFilter.searchText);

  const handleChangeSearchText = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    if (text.length < 2) {
      setSearchText('');
    } else {
      setSearchText(text);
    }
  };

  const handleLostFocus = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const text = e.target.value;
    if (text.length < 2) {
      setSearchText('');
      searchTextRef.current!.value = '';
    }
  };

  const debouncedHandleChangeSearchText = debounce(handleChangeSearchText, SEARCH_TIMEOUT);

  const handleClickClear = (): void => {
    setSearchText('');
    searchTextRef.current!.value = '';
  };

  const updateFilter = (): void => {
    setInvoicesPageFilter({
      ...invoicesPageFilter,
      searchText: searchText
    });
  };

  useNonInitialEffect(() => {
    updateFilter();
  }, [searchText]);

  useEffect(() => {
    searchTextRef.current!.value = invoicesPageFilter.searchText;
  }, []);

  return (
    <div className="relative flex w-[376px]">
      <img src={IconSearch} alt="search" className="s2pim-svg-sadlilac absolute left-3 top-[10px] h-[14px] w-[14px]" />
      <input
        className="h-8 w-full rounded border border-[var(--color-secondary-sadlilac)] bg-[var(--color-table-row)] pl-8 pr-6 pt-[1px] text-sm text-[var(--color-secondary-sadlilac)] focus:border-[var(--color-primary-mint)] focus:outline-none"
        type="input"
        onChange={debouncedHandleChangeSearchText}
        onBlur={handleLostFocus}
        // placeholder="Search..."
        placeholder="Search by Invoice No." // TODO: Change to "Search..." when the API supports the search across different fields.
        ref={searchTextRef}
      />
      {searchText && (
        <button className="s2pim-search-bar--btn absolute right-1 top-[4px]" onClick={handleClickClear}>
          <img src={IconClose} alt="Clear search text" />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
