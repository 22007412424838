import { User } from '@/model/user';
import { createContext } from 'react';
import { IAppDataContext } from './appData.types';

/* DEFAULT VALUES */

export const defaultUser: User = {
  name: ''
};

/* EMPTY CONTEXT */

export const AppDataContext = createContext<IAppDataContext>({} as IAppDataContext);
