import IconRejected from '@/assets/icons/icon-doc-canceled.svg';
import IconApproved from '@/assets/icons/icon-doc-check.svg';
import IconPosted from '@/assets/icons/icon-doc-currency.svg';
import IconPostingError from '@/assets/icons/icon-doc-info.svg';
import IconReadyToSubmit from '@/assets/icons/icon-doc-upload.svg';
import IconVoid from '@/assets/icons/icon-doc-void.svg';
import IconPendingApproval from '@/assets/icons/icon-pending-approval.svg';
import IconPendingReview from '@/assets/icons/icon-pending-review.svg';
import IconProcessing from '@/assets/icons/icon-processing.svg';
import IconUnpaid from '@/assets/icons/icon-wallet-canceled.svg';
import IconPaid from '@/assets/icons/icon-wallet-check.svg';

export const INVOICE_STATUS = [
  {
    status: 'Pending Review',
    icon: IconPendingReview,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: true,
    hasWorkflowActions: true
  },
  {
    status: 'Discarded',
    icon: IconVoid,
    background: 'bg-[var(--color-functional-negative-danger)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Ready to Submit',
    icon: IconReadyToSubmit,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: true,
    hasWorkflowActions: true
  },
  {
    status: 'Pending Approval',
    icon: IconPendingApproval,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: true
  },
  {
    status: 'Approved',
    icon: IconApproved,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Rejected',
    icon: IconRejected,
    background: 'bg-[var(--color-functional-negative-danger)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Posted',
    icon: IconPosted,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Posting Error',
    icon: IconPostingError,
    background: 'bg-[var(--color-functional-negative-danger)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Paid',
    icon: IconPaid,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Paid externally',
    icon: IconPaid,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },

  {
    status: 'Unpaid',
    icon: IconUnpaid,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Void',
    icon: IconVoid,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Reconciled',
    icon: IconApproved,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Paying',
    icon: IconProcessing,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Canceled',
    icon: IconVoid,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  },
  {
    status: 'Processing',
    icon: IconProcessing,
    background: 'bg-[var(--color-secondary-sadlilac)]',
    hasEditActions: false,
    hasWorkflowActions: false
  }
];
