import { showConfidenceOrUnknownValues } from '../utils/showConfidenceOrUnknownValues';
import { DetailValueProps } from './types';

const DetailValue = ({ field, children }: DetailValueProps) => {
  const { name, value: invoiceValue } = field;

  return (
    <div className="flex flex-row overflow-hidden">
      <p className="truncate text-xs font-bold" children={children || '-'} />
      {showConfidenceOrUnknownValues(name, invoiceValue)}
    </div>
  );
};

export default DetailValue;
