import { JSX } from 'react';
import './Button.css';
import { IButtonProps } from './types';

const Button = ({
  value,
  icon,
  onClick,
  borderVariant = 'rectangular',
  colorVariant = 'purple',
  options,
  className,
  dataTestId
}: IButtonProps): JSX.Element => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`s2pim-button s2pim-button--${borderVariant} s2pim-button--${colorVariant} ${
        options ? 's2pim-button--' + options : ''
      } ${className || ''}`}
      data-testid={dataTestId}
    >
      {icon && <img src={icon} alt="Icon" />}
      {value}
    </button>
  );
};

export default Button;
