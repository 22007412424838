import IconPdf from "@/assets/icons/icon-pdf-light.svg";
import IconCsv from "@/assets/icons/icon-csv-40px.svg";
import IconPdfSmall from "@/assets/icons/icon-pdf-small.svg";
import IconCsvSmall from "@/assets/icons/icon-csv-small.svg";

export interface IIconConfig {
  fileType: string;
  icon: string;
  altText: string;
}

export const iconMapping = (iconStyle: string): { csv: string, pdf: string } => {
  if (iconStyle === "small") {
    return {
      csv: IconCsvSmall,
      pdf: IconPdfSmall
    };
  } else return {
    csv: IconCsv,
    pdf: IconPdf
  };
};

interface IGetDisplayIcon {
  iconStyle: "small" | "regular";
  fileType: string;
  className?: string;
}

export const getDisplayIcon = (
  {
    iconStyle,
    fileType,
    className = ""
  }: IGetDisplayIcon
): JSX.Element => {
  const mappedIcons = iconMapping(iconStyle);
  const iconConfig = [
    { fileType: "csv", icon: mappedIcons.csv, altText: "CSV Details" },
    { fileType: "pdf", icon: mappedIcons.pdf, altText: "PDF Details" }
  ];

  return (
    <>
      {
        iconConfig.map((config: IIconConfig) => {
          return (
            <div key={config.altText}>
              {fileType === config.fileType && (
                <img
                  src={config.icon}
                  alt={config.altText}
                  className={className}
                />
              )}
            </div>
          );
        })
      }
    </>
  );
};