// CONSTANTS

export const COLORS = {
  'primary-brightlupine': '#7595f5',
  'primary-mint': '#bff3d2',
  'chatbot-camanay': '#38c1ec',
  'functional-negative-careful': '#ffa858'
};

export const FILTERS = {};

export const GRADIENTS = {};

export const BOX_SHADOWS = {};

export const SIZES = [4, 8, 12, 16, 20, 24, 28, 32, 36, 40];
