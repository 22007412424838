import { DetailedNumberValue, DetailedStringValue } from '@/model/invoice';
import ParsedConfidence from '../../Common/ParsedConfidence/ParsedConfidence';
import ParsedContent from '../../Common/ParsedContent/ParsedContent';

export const showConfidenceOrUnknownValues = (
  name: string,
  invoiceValue: DetailedStringValue | DetailedNumberValue
) => {
  const inputShowsOnlyValues = ['documentNo', 'documentDate', 'status', 'type', 'priority', 'totalTaxAmount'].some(
    inputName => inputName === name
  );
  if (inputShowsOnlyValues) return;
  return ParsedContent(invoiceValue) || ParsedConfidence(invoiceValue);
};
