import IconRing from '@/assets/icons/icon-ring.svg';
import { JSX } from 'react';
import './SpinnerAiops.css';

const SpinnerAiops = (): JSX.Element => {
  return (
    <div className="s2pim-spinner-container">
      <img className="s2pim-spinner-loading" alt="Icon" src={IconRing} />
      <p className="s2pim-spinner-content">Loading...</p>
    </div>
  );
};

export default SpinnerAiops;
