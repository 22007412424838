import { InvoiceDashboardProvider, useAppDataContext } from '@/context';
import { JSX, useEffect, useState } from 'react';
import { AccessConfiguration } from '@/constants/accessConfiguration.constants';
import {
  CardInvoicesSummary,
  DashboardTable,
  SearchBar,
  TableInvoiceActions
} from "@/components/modules/InvoiceDashboard";


const InvoiceDashboard = (): JSX.Element => {
  const {
    state: { appName, appPermissions },
    actions: { getUserAccessForResource }
  } = useAppDataContext();

  const [ userAccess, setUserAccess ] = useState<Record<string, Record<string, boolean>>>({
    accessDashBoardPage: { access: false }
  });
  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      accessDashBoardPage: getUserAccessForResource(AccessConfiguration.page.dashboard)
    })
  }, [appPermissions]);

  return (
    <>
    { userAccess.accessDashBoardPage?.access && (<InvoiceDashboardProvider>
      <div className="page_content">
        <div className="flex w-full flex-col">
          <div className="flex w-full flex-col">
            <div className="flex items-center justify-between">
              <h1 className="text-[44px] font-semibold">{appName}</h1>
              <SearchBar />
            </div>
            <CardInvoicesSummary />
          </div>
          <TableInvoiceActions />
          <DashboardTable />
        </div>
      </div>
    </InvoiceDashboardProvider>)}
    </>
  );
};

export default InvoiceDashboard;
