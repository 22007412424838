import { IInvoicesPageFilter, IInvoicesSummary, Invoice, InvoiceFieldSorting, InvoiceStatus } from '@/model/invoice';
import { Page } from '@/model/pagination';
import { createContext } from 'react';
import { IInvoiceDashboardContext } from './invoiceDashboard.types';

/* DEFAULT VALUES */

export const DEFAULT_INVOICES_SUMMARY: IInvoicesSummary = {
  total: 0,
  totalPendingReview: 0,
  totalReadyToSubmit: 0,
  totalPendingApproval: 0,
  totalAge1to5: 0,
  totalAge6to10: 0,
  totalAge11to15: 0,
  totalAge16plus: 0,
  totalAgeUndefined: 0,
  totalPastDue: 0,
  totalDueInLessThan3Days: 0,
  totalDueIn3To5Days: 0,
  totalUndefined: 0
};

export const DEFAULT_INVOICES_PAGE: Page<Invoice> = {
  data: [],
  page: 0,
  size: 0,
  total: 0
};

export const DEFAULT_INVOICES_PAGE_FILTER: IInvoicesPageFilter = {
  page: 1,
  pageSize: 10,
  statuses: new Set<InvoiceStatus>(),
  sorting: new Set<InvoiceFieldSorting>(),
  searchText: ''
};

export const DEFAULT_INVOICES_TABLE_PAGINATION_OPTIONS = [5, 10, 20, 25, 50, 100];

/* EMPTY CONTEXT */

export const InvoiceDashboardContext = createContext<IInvoiceDashboardContext>({} as IInvoiceDashboardContext);
