import { COLORS } from '@/assets/styles/theme';
import { ArcElement, Chart as ChartJS, Legend, LinearScale, Tooltip } from 'chart.js';
import { JSX } from 'react';
import { Pie } from 'react-chartjs-2';
import { IGraphInvoicesByStatusProps } from './types';
ChartJS.register(ArcElement, LinearScale, Tooltip, Legend);

const GraphInvoicesByStatus = ({
  totalPendingReview = 0,
  totalReadyToSubmit = 0,
  totalPendingApproval = 0
}: IGraphInvoicesByStatusProps): JSX.Element => {
  const total = totalPendingReview + totalReadyToSubmit + totalPendingApproval;

  const chartData = {
    labels: ['Pending Review', 'Ready to Submit', 'Pending Approval'],
    datasets: [
      {
        label: 'Invoices',
        data: [totalPendingReview, totalReadyToSubmit, totalPendingApproval],
        backgroundColor: [
          COLORS['functional-negative-careful'],
          COLORS['chatbot-camanay'],
          COLORS['primary-brightlupine']
        ],
        borderColor: [COLORS['functional-negative-careful'], COLORS['chatbot-camanay'], COLORS['primary-brightlupine']],
        hoverOffset: 0
      }
    ]
  };

  const chartOptions = {
    animation: {
      animateRotate: true
    },
    plugins: {
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      }
    }
  };

  return (
    <div className="flex flex-col">
      <h2 className="mb-1 text-sm font-bold">Open Invoices by Status</h2>
      <div className="flex items-center">
        <div className="mb-3 mt-5 h-36 w-36">
          {total > 0 ? (
            <Pie data={chartData} options={chartOptions} />
          ) : (
            <div className="h-36 w-36 rounded-full bg-[var(--color-secondary-sadlilac)]"></div>
          )}
        </div>
        <ul className="ml-6 text-xs font-normal text-[var(--color-secondary-sadlilac)]">
          <li className="flex h-8 items-center gap-2">
            <div className="h-4 w-4 rounded-full bg-[var(--color-functional-negative-careful)]"></div>
            <div data-testid="graph-invoices-status-pending-review">{totalPendingReview} Pending Review</div>
          </li>
          <li className="flex h-8 items-center gap-2">
            <div className="h-4 w-4 rounded-full bg-[var(--color-chatbot-camanay)]"></div>
            <div data-testid="graph-invoices-status-ready-to-submit">{totalReadyToSubmit} Ready to Submit</div>
          </li>
          <li className="flex h-8 items-center gap-2">
            <div className="h-4 w-4 rounded-full bg-[var(--color-primary-brightlupine)]"></div>
            <div data-testid="graph-invoices-status-pending-approval">{totalPendingApproval} Pending Approval</div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default GraphInvoicesByStatus;
