import { InlineBadge, toast } from '@/components/ui';
import { useInvoiceDetailsContext } from '@/context';
import { IInvoiceActions, InvoiceDetails, Item } from '@/model/invoice';
import { downloadInvoice } from '@/services';
import * as FileManager from '@/services/file-management.service';
import { currencyFormatter } from '@/utils';
import { AxiosError } from 'axios';
import { JSX, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import DisplayInvoiceStatus from '../../Common/DisplayInvoiceStatus/DisplayInvoiceStatus';
import { INVOICE_STATUS } from '../constants/invoice-status';
import { INVOICE_VALIDATION_SCHEMA } from '../constants/invoice-validation';
import useGetInvoiceLogs from '../hooks/useGetInvoiceLogs';
import useGetWorkflowDecisionQuery from '../hooks/useGetWorkflowDecision';
import useInvoiceQuery from '../hooks/useInvoiceQuery';
import HeaderData from './HeaderData';
import { getDisplayIcon } from '@/components/modules/Common/IconDisplay';
//@ts-ignore
import { Button } from "@aiops/styleguide";

const InvoiceHeader = (): JSX.Element => {
  const [query] = useSearchParams();
  const documentNo = query.get('id') || '-';
  const { data: invoiceCalled, status: requestInvoice } = useInvoiceQuery(documentNo);
  const { data: workflowDecision, status: requestWorkflows } = useGetWorkflowDecisionQuery();
  const { data: invoiceLogs, status: requestInvoiceLogs } = useGetInvoiceLogs(documentNo);
  const {
    state: { invoice, invoiceItems },
    actions: { setInvoice, setInvoiceItems, setInvoiceStatus, setInvoiceIsReady, setInvoiceLogs, setShowActions }
  } = useInvoiceDetailsContext();
  const headerStatus = INVOICE_STATUS.find(
    ({ status }) => status?.toLowerCase().trim() === invoice?.status?.toLowerCase().trim()
  );
  const isPotentiallyDuplicated = invoice.isPotentiallyDuplicated;
  const invoiceActions: IInvoiceActions | undefined = headerStatus
    ? { hasEditActions: headerStatus.hasEditActions, hasWorkflowActions: headerStatus.hasWorkflowActions }
    : undefined;

  // const potentiallyDuplicateOfDocumentNo = invoice.potentiallyDuplicateOfDocumentNo;
  // const warningMsg = invoice.potentiallyDuplicateOfDocumentNo ? (
  //   <>
  //     Potential duplicate with record No. <strong>{potentiallyDuplicateOfDocumentNo}</strong>
  //   </>
  // ) : (
  //   'Potential duplicate.'
  // );
  const warningMsg = <p>Potential Duplicate</p>;

  useEffect(() => {
    if (requestInvoice === 'success' && requestWorkflows === 'success') {
      setInvoice(invoiceCalled as InvoiceDetails);
      setInvoiceItems(invoiceCalled.items as Item[]);
      setInvoiceStatus(workflowDecision?.statusFlows.filter(({ current }) => current === invoiceCalled?.status));
      setShowActions(invoiceActions);
    }

    if (requestInvoiceLogs === 'success') {
      setInvoiceLogs(invoiceLogs);
    }
  }, [requestInvoice, requestWorkflows, requestInvoiceLogs, headerStatus]);

  useEffect(() => {
    const checkValidation = async () =>
      INVOICE_VALIDATION_SCHEMA.validate(await invoice)
        .then(() => setInvoiceIsReady(true))
        .catch(() => setInvoiceIsReady(false));

    checkValidation();
  }, [invoice, invoiceItems]);

  const errorHandlerGetFile = (error: AxiosError) => {
    if (error.code === 'ERR_NETWORK') {
      toast(error.message, 'error');
    } else if (error.code === 'ERR_BAD_RESPONSE') {
      toast('Error getting invoice file', 'error');
    }
  };

  const getInvoicePdf = () => {
    FileManager.getFile(downloadInvoice, errorHandlerGetFile)(documentNo);
  };

  return (
    <section className="flex flex-col">
      <div className="flex">
        <h1 className="pb-[48px] text-[44px] font-semibold">Invoice No. {invoice.invoiceNo?.value}</h1>
        {isPotentiallyDuplicated && (
          <div className="ml-3 mt-4 flex">
            <InlineBadge variant="warning" />
            <p className="ml-2 text-xs font-normal text-[var(--color-functional-negative-careful)]">{warningMsg}</p>
          </div>
        )}
        {headerStatus && <DisplayInvoiceStatus {...headerStatus} />}
      </div>
      <div className="flex justify-between">
        <div className="flex">
          <Button className="mx-5 flex h-10 w-10 cursor-pointer self-center" onClick={getInvoicePdf}>
            {getDisplayIcon({
              iconStyle: "regular",
              fileType: invoice.fileType,
              className: "h-10 w-10 rounded border border-solid bg-[var(--color-primary-hibiscus)]"
            })}
          </Button>
          <HeaderData title="Supplier" value={invoice.supplier?.value} />
          <HeaderData title="Gross Amt. (tax incl.)" value={currencyFormatter(invoice.grossAmount?.value as number)} />
          <HeaderData title="Invoice Type" value={invoice.type || 'Standard'} />
        </div>
      </div>
    </section>
  );
};

export default InvoiceHeader;
