export const DEFAULT_RES_PER_PAGE_OPTS = [
    5,
    10,
    20,
    25,
    50,
    100,
];


export const columns = [
    {
      field: "fileType",
      title: "fileType",
    },
    {
      field: "invoiceNo",
      title: "Invoice No.",
    },
    {
      field: "supplier",
      title: "Supplier",
    },
    {
      field: "invoiceDate",
      title: "Invoice Date",
    },
    {
      field: "currency",
      title: "Currency",
    },
    {
      field: "grossAmount",
      title: "Gross Amount",
    },
    {
      field: "submitter",
      title: "Submitter",
    },
    {
      field: "requester",
      title: "Requester",
    },
    {
      field: "priority",
      title: "Priority",
    },
    {
      field: "status",
      title: "Status",
    },
  ];

export const rowPropsConfig = {
  cellClassNames: {
    fileType: 'dashboard-table-fileType'
  }
};