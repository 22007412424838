import { createContext, useContext } from 'react';
import { IFile } from './types';

const FilesLoadedContext = createContext<IFile | null>(null);

export const useFilesLoadedContext = () => {
  const context = useContext(FilesLoadedContext);

  if (!context) {
    throw new Error('*Files Context*.Component must be rendered as child of FileLoadedProgress component');
  }
  return context;
};

export default FilesLoadedContext;
