import IconCheckbox from '@/assets/icons/icon-checkbox.svg';
import { ColDef, GridSizeChangedEvent, ModelUpdatedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { JSX, useRef } from 'react';
import './TableConfidenceScoreThresholds.css';

interface IConfidenceScoreThreshold {
  field: string;
  fieldName: string;
  value: number;
}

const DEFAULT_THRESHOLD = 0.9;
const THRESHOLDS: IConfidenceScoreThreshold[] = [
  { field: 'invoiceNo', fieldName: 'Currency', value: DEFAULT_THRESHOLD },
  { field: 'invoiceDate', fieldName: 'Invoice Date', value: DEFAULT_THRESHOLD },
  { field: 'dueDate', fieldName: 'Due Date', value: DEFAULT_THRESHOLD }
  // { field: 'businessUnit', fieldName: 'Business Unit', value: DEFAULT_THRESHOLD },
  // { field: 'supplier', fieldName: 'Supplier', value: DEFAULT_THRESHOLD },
  // { field: 'supplierId', fieldName: 'Supplier ID', value: DEFAULT_THRESHOLD },
  // { field: 'grossAmount', fieldName: 'Gross Amt. (tax incl.)', value: DEFAULT_THRESHOLD },
  // { field: 'netAmount', fieldName: 'Net Amount (tax excl.)', value: DEFAULT_THRESHOLD },
  // { field: 'totalTaxAmount', fieldName: 'Total tax', value: DEFAULT_THRESHOLD },
  // { field: 'currency', fieldName: 'Currency', value: DEFAULT_THRESHOLD },
  // { field: 'paymentTerms', fieldName: 'Payment Terms', value: DEFAULT_THRESHOLD },
  // { field: 'priority', fieldName: 'Payment Priority', value: DEFAULT_THRESHOLD },
  // { field: 'inputter', fieldName: 'Inputter', value: DEFAULT_THRESHOLD },
  // { field: 'requester', fieldName: 'Requester', value: DEFAULT_THRESHOLD }
];

const TableConfidenceScoreThresholds = (): JSX.Element => {
  const gridRef = useRef<AgGridReact<IConfidenceScoreThreshold>>(null);

  const defaultColDef: ColDef = {
    wrapText: true,
    autoHeight: true,
    suppressMovable: true
  };

  const columnDefs: ColDef[] = [
    {
      headerComponent: (): JSX.Element => {
        return <img src={IconCheckbox} alt="Checkbox" className="mx-auto" />;
      },
      cellRenderer: (): JSX.Element => {
        return (
          <button className="threshold-check-btn">
            <img src={IconCheckbox} alt="Checkbox" className="mx-auto" />
          </button>
        );
      },
      maxWidth: 40
    },
    {
      headerName: 'Field Name',
      field: 'fieldName'
    },
    {
      headerName: 'Threshold',
      field: 'value'
    },
    {
      headerName: 'Actions',
      field: '',
      maxWidth: 130
    }
  ];

  return (
    <div className="ag-theme-s2pim s2pim-table-threshold mt-2 w-full flex-1">
      <AgGridReact<IConfidenceScoreThreshold>
        ref={gridRef}
        rowData={THRESHOLDS}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        ensureDomOrder
        enableCellTextSelection
        suppressPaginationPanel
        suppressCellFocus
        rowHeight={69} // CSS variable --ag-row-height is not working as expected when adding spacing between rows.
        onGridSizeChanged={(event: GridSizeChangedEvent<IConfidenceScoreThreshold>): void =>
          event.api.sizeColumnsToFit()
        }
        onModelUpdated={(event: ModelUpdatedEvent<IConfidenceScoreThreshold>): void => event.api.sizeColumnsToFit()}
        overlayNoRowsTemplate="There are no invoices to show."
      />
    </div>
  );
};

export default TableConfidenceScoreThresholds;
