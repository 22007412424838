/**
 * Debounce
 *
 * @param callback Function to be delayed.
 * @param delay Time in milliseconds to wait before executing the callback.
 * @returns A function that wi
 * @doc
 * Debouncing is a programming pattern or a technique to restrict the calling
 * of a time-consuming function frequently, by delaying the execution of the
 * function until a specified time to avoid unnecessary CPU cycles, and API
 * calls and improve performance.
 */
export const debounce = (callback: (...args: any[]) => any, delay: number): ((...args: any[]) => void) => {
  let timeoutID: ReturnType<typeof setTimeout>;
  return (...args: any): void => {
    const context = this;
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      callback.apply(context, args);
    }, delay);
  };
};
