import { JSX } from 'react';
import { IDisplayInvoiceStatusPropsProps } from './types';

const DisplayInvoiceStatus = ({ status, icon, background }: IDisplayInvoiceStatusPropsProps): JSX.Element => {
  return (
    <div className="ml-auto flex items-center gap-3 self-center pb-[48px]">
      <p className="text-base font-bold">{status}</p>
      <div className={`flex h-8 w-8 items-center justify-center rounded-full ${background}`}>
        <img src={icon} alt={status} />
      </div>
    </div>
  );
};

export default DisplayInvoiceStatus;
