import { DialogModalProps } from '@/components/ui/DialogModal/types';
import * as React from 'react';
import { JSX } from 'react';
import './DialogModal.css';
//@ts-ignore
import { Button, Typography } from "@aiops/styleguide";

const modalButtons = (
  cancelButtonText: string | React.ReactNode,
  onCancel: (() => void) | undefined,
  confirmationButtonText: string | React.ReactNode,
  onConfirm: (() => void) | undefined,
  confirmEnable: boolean = true
): JSX.Element => {
  if (cancelButtonText && !onCancel) {
    throw new Error('Cancel button text supplied, but no onCancel function.');
  }

  if (confirmationButtonText && !onConfirm) {
    throw new Error('Confirmation button text supplied, but no onConfirm function.');
  }
  return (
    <div className="button-container">
      {cancelButtonText && (
        <Button variant="outlined" onClick={onCancel}>
          {cancelButtonText}
        </Button>
      )}
      {confirmationButtonText && (
        <Button variant="contained" onClick={onConfirm} disabled={!confirmEnable}>
          {confirmationButtonText}
        </Button>
      )}
    </div>
  );
};

const DialogModal = ({
  onClose,
  headlineText,
  bodyText,
  children,
  cancelButtonText,
  onCancel,
  confirmationButtonText,
  onConfirm,
  variant,
  confirmEnable
}: DialogModalProps): JSX.Element => {
  return (
    <>
      <div data-testid="modal-container" className="s2pim-dialog-modal">
        {headlineText && (
          <Typography align="center" variant="h4">
            {headlineText}
          </Typography>
        )}
        {bodyText && (
          <Typography align="center" variant="body1" >
            {bodyText}
          </Typography>
        )}
        {children && (
          <div data-testid="children-passed" className={`content-wrapper`}>
            {children}
          </div>
        )}
        {(cancelButtonText || confirmationButtonText) &&
          modalButtons(cancelButtonText, onCancel, confirmationButtonText, onConfirm, confirmEnable)}
      </div>
    </>
  );
};

export default DialogModal;
