import { IFile } from '@/components/modules/Common/FileUploads/types';
import { FileStatusCode } from '@/constants/fileStatus.constants';
import { postUploadInvoices } from '@/services';
import { AxiosError, AxiosProgressEvent } from 'axios';
import { Dispatch, SetStateAction } from 'react';

type ProcessFile = FileList | null;

const uploadFiles = (setState: Dispatch<SetStateAction<IFile[]>>, notifyCallBack: (files: IFile[]) => void) => {
  return (files: ProcessFile, userName: string): void => {
    const chosenFiles = Array.prototype.slice.call(files);
    let count = 0;
    let filesCopy = [...chosenFiles];

    chosenFiles.forEach(file => {
      const formData = new FormData();
      formData.append('file', file);
      const controller = new AbortController();
      const abortSignal = controller.signal;
      const newFile = file;
      const fileIndex = filesCopy.findIndex(fileCopy => fileCopy.name === file.name);
      postUploadInvoices(formData, userName, abortSignal, {
        onUploadProgress: (progressEvent: AxiosProgressEvent) => {
          const { loaded, total } = progressEvent;
          const progress = Math.floor((loaded * 100) / total!);
          newFile.inProgress = true;
          newFile.progress = progress;
          newFile.isCanceled = false;
          newFile.error = false;
          newFile.abortControl = controller;
          filesCopy[fileIndex] = newFile;
          setState([...filesCopy]);
        }
      })
        .then(response => {
          const [isError, callResponse] = response;
          newFile.inProgress = false;
          newFile.error = isError;
          if (isError) {
            newFile.errorMessage = callResponse.response.data.message || FileStatusCode[callResponse.code] || callResponse.message;
          }
          newFile.response = FileStatusCode[callResponse.code] || callResponse.message;
          newFile.isCanceled = callResponse.code === 'ERR_CANCELED';
          filesCopy = [...chosenFiles];
          filesCopy[fileIndex] = newFile;
          setState([...filesCopy]);

          if (isError !== undefined) {
            count++;
            if (count === chosenFiles.length) {
              notifyCallBack(filesCopy);
            }
          }
        })
        .catch((error: AxiosError | any) => {
          newFile.inProgress = false;
          newFile.error = true;
          filesCopy = [...chosenFiles];
          filesCopy[fileIndex] = newFile;
          setState([...filesCopy]);
          notifyCallBack([...filesCopy]);
        });
    });
  };
};

export default uploadFiles;
