import { IInvoicePatchRaw, IItemPatchRaw, IInvoicePatch, IItemPatch } from '@/services/invoice.types';

type Y = IInvoicePatchRaw | IItemPatchRaw;

const transformValuesKeysToRaw = <T extends IInvoicePatch | IItemPatch>(fieldsToRaw: any, dataToTransform: T): Y => {
  let dataRawUpdated: Y = {};
  for (const [keyPatch, valuePatch] of Object.entries(dataToTransform)) {
    let rawKey = fieldsToRaw[keyPatch as keyof T];
    dataRawUpdated = {
      ...dataRawUpdated,
      [rawKey]: valuePatch
    };
  }
  return dataRawUpdated;
};

export default transformValuesKeysToRaw;
