import { useInvoiceDetailsContext } from '@/context';
import { IInvoiceLog } from '@/model/invoice';
import { JSX } from 'react';
import CardLogs from './CardLogs';

const ViewLogs = (): JSX.Element => {
  const {
    state: { invoice, invoiceLogs }
  } = useInvoiceDetailsContext();

  return (
    <div className="flex flex-col gap-y-1">
      {invoiceLogs?.map((invoiceLog: IInvoiceLog) => (
        <CardLogs key={`${invoiceLog.action}`} {...invoiceLog} />
      ))}
      <CardLogs
        key={'Invoice Created'}
        action={'Invoice Created'}
        updatedAt={invoice.documentDate}
        userName={invoice.submitter.value || ''}
        comment={''}
      />
    </div>
  );
};

export default ViewLogs;
