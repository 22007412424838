import IconAlert from '@/assets/icons/icon-alert-light.svg';
import IconCheck from '@/assets/icons/icon-check.svg';
import IconCancel from '@/assets/icons/icon-close.svg';
import IconEdit from '@/assets/icons/icon-edit.svg';
import { useAppDataContext, useInvoiceDetailsContext } from '@/context';
import { Dispatch, JSX, SetStateAction, useEffect, useState } from 'react';
import Button from '../Elements/Button/Button';
import { AccessConfiguration } from '@/constants/accessConfiguration.constants';

interface IProps {
  onEditCancel: () => void;
  startEditGrid: () => void;
  onEditSave: () => void;
  isValid: boolean;
  exceptionsCount: number;
  setFilterRows: Dispatch<SetStateAction<boolean>>;
  filterRows: boolean;
  editMode: boolean;
}

const ActionsTable = ({
  startEditGrid,
  onEditCancel,
  onEditSave,
  isValid,
  exceptionsCount,
  setFilterRows,
  filterRows,
  editMode
}: IProps): JSX.Element => {
  const {
    state: { showActions }
  } = useInvoiceDetailsContext();
  const {
    state: { appPermissions },actions: { getUserAccessForResource }
  } = useAppDataContext();
  const [ userAccess, setUserAccess ] = useState<Record<string, Record<string, boolean>>>({
    editTableAction: { write: false }
  });
  
  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      editTableAction: getUserAccessForResource(AccessConfiguration.pageComponents.edit_table_actions)
    })
  }, [appPermissions]);

  return (
    <div className="absolute right-0 top-[-44px] mb-1 flex gap-2">
      <Button
        key="exceptions-btn"
        variant="secondary"
        icon={IconAlert}
        value={`Exceptions (${exceptionsCount})`}
        disabled={exceptionsCount === 0}
        onClick={() => setFilterRows(prev => !prev)}
        styles={filterRows ? 'active' : ''}
      />
      {userAccess.editTableAction?.write && showActions?.hasEditActions && (
        <>
          {editMode ? (
            <>
              <Button
                key="cancel-btn"
                variant="secondary"
                icon={IconCancel}
                value="Cancel"
                onClick={onEditCancel}
                type="reset"
              />
              <Button
                key="save-btn"
                variant="secondary"
                icon={IconCheck}
                value="Save"
                onClick={onEditSave}
                type="submit"
                disabled={!isValid}
              />
            </>
          ) : (
            <Button
              key="edit-btn"
              variant="secondary"
              icon={IconEdit}
              value="Edit Items"
              onClick={startEditGrid}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ActionsTable;
