import { JSX } from 'react';
import { IInputProps } from '../ViewDetail/types';
import './Input.css';

const Input = (props: IInputProps): JSX.Element => {
  const { customClasses, ...rest } = props;
  return <input {...rest} className={`s2pim-invoice-details-input ${customClasses}`} />;
};

export default Input;
