import { ICellEditorParams } from 'ag-grid-community';
import { Field } from 'formik';
import { JSX, forwardRef, useImperativeHandle } from 'react';
import ParsedConfidence from '../../Common/ParsedConfidence/ParsedConfidence';
import ParsedContent from '../../Common/ParsedContent/ParsedContent';
import InputTable from './InputTable';

const RenderEditCells = forwardRef((props: ICellEditorParams, ref): JSX.Element => {
  const { value, colDef, data } = props;

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
      isCancelBeforeStart() {
        return false;
      }
    };
  });

  return (
    <>
      <Field
        component={InputTable}
        type="text"
        name={`${colDef.field!}.value`}
        {...props}
        value={data[colDef.field!].value}
      />
      {ParsedContent(value) || ParsedConfidence(value)}
    </>
  );
});

export default RenderEditCells;
