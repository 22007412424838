import format from 'date-fns/format';
import { isEmpty } from './validations.utils';

/**
 * Format number to currency
 *
 * @param {number} value The number to format.
 * @param {string} currency The currency to format the number. Usually 'USD'.
 * @returns {string} The formatted number as string.
 * @example
 * <div>{currencyFormatter(1234.56, 'USD');}</div>
 * It renders $1,234,56
 */
export const currencyFormatter = (value: number, currency: string = 'USD', language: string = 'en-US'): string => {
  if (!value) return '-';
  return new Intl.NumberFormat(language, {
    currency: currency,
    style: 'currency'
  }).format(value);
};

/**
 * Returns a hyphen if the string is empty
 *
 * @param {string|null|undefined} value String to be checked.
 * @returns {string} A non empty string, the same string passed in or a hyphen.
 */
export const emptyFormatter = (value: string | null | undefined): string => {
  if (!value) return '-';
  return value;
};

/**
 * Format a decimal number to percent
 *
 * @param {number} value Decimal number
 * @param {number} fractionDigits Number of digits after the decimal point. Default 2.
 * @returns {string} The string representation of the decimal in percent format.
 * @example input 0.9555 output 96%
 */
export const percentFormatter = (value: number, fractionDigits: number = 2): string => {
  if (!value) return '-';
  return (value * 100).toFixed(fractionDigits) + '%';
};

/**
 * Format a date object to a string
 *
 * @param {Date} value Date in ISO-8601 standard and format YYYY-MM-DDTHH:mm:ss.
 * @returns {string} The string representation of the date in format MM/DD/YYYY.
 */
export const dateFormatter = (value: Date): string => {
  if (!value) return '-';
  try {
    return format(value, 'MM/dd/yyyy');
  } catch (error) {
    return '-';
  }
};

/**
 * Format an object to a URL params string
 *
 * @param {any} params The object to format.
 * @returns {string} The string representation of the object.
 * @example
 * Input params = {
 *   page: 1,
 *   size: 100
 * };
 * Output page=1&size=100
 */
export const uriParamsFormatter = (params: any): string => {
  if (isEmpty(params)) return '';
  return Object.keys(params)
    .map((key: string) => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
};
