/**
 * Checks if is an empty object, collection, map, or set.
 *
 * @param object The object to check.
 * @returns True or false if the object is empty.
 * @doc
 * Objects are considered empty if they have no own enumerable string keyed
 * properties.
 */
export const isEmpty = (object: any): boolean => Object.keys(object).length === 0;

/**
 * Check if is null or undefined
 *
 * @param {any} value Value to check.
 * @returns {boolean} Returns true if value is null or undefined.
 * @doc
 * "if(value){}" Will evaluate to true if value is not: null, undefined, NaN, empty string '', 0, false.
 * This function checks only if a value is null or undefined.
 */
export const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;

/**
 * Check if is a valid number
 *
 * @param {number} value Value to check.
 * @returns {boolean} Returns true if value is not null or undefined, and is a valid number.
 */
export const isValidNumber = (value: number): boolean =>
  !isNullOrUndefined(value) && value.toString().trim().length !== 0 && !isNaN(+value);
