import { InlineBadge, Tooltip } from '@/components/ui';
import { DetailedValue } from '@/model/invoice';
import { isValidNumber, percentFormatter } from '@/utils';
import { JSX } from 'react';

const ParsedConfidence = (invoiceDetailedValue: DetailedValue): JSX.Element | false => {
  if (!invoiceDetailedValue) return false;
  if (invoiceDetailedValue.edited) return false;
  if (invoiceDetailedValue.exceptions === false) return false;

  const confidenceRaw = invoiceDetailedValue.confidence ? invoiceDetailedValue.confidence : NaN;
  if (!isValidNumber(confidenceRaw)) return false;

  const confidence = Number(confidenceRaw);
  const confidencePercent = percentFormatter(confidence, 0);
  const content = (
    <p>
      Confidence: <strong>{confidencePercent}</strong>
    </p>
  );

  return (
    <Tooltip content={content} variant="warning">
      <div className="ml-1 pl-0.5">
        <InlineBadge variant="warning" />
      </div>
    </Tooltip>
  );
};

export default ParsedConfidence;
