import React, { JSX } from 'react';
import ReactDOM from 'react-dom';
import { AppProps } from 'single-spa';
import singleSpaReact from 'single-spa-react';
import { CustomAppProps } from './model/platform';
import Root from './root.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err: Error, errInfo: React.ErrorInfo, props: CustomAppProps & AppProps): JSX.Element {
    // Customize the root error boundary for your microfrontend here.
    return <></>;
  }
});

export const { bootstrap, unmount } = lifecycles;

export const mount = async (props: AppProps & CustomAppProps): Promise<void> => {
  return Promise.resolve().then(() => {
    lifecycles.mount(props);
  });
};
