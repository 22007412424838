import { JSX } from 'react';

const InvoiceSettingsWorkflowConfig = (): JSX.Element => {
  return (
    <div className="invoice-dashboard flex w-full">
      <div className="mx-10 flex w-full flex-col pt-5">
        <div className="flex w-full flex-col">
          <div className="flex items-center justify-between">
            <h1 className="text-[44px] font-semibold">Confidence Score Thresholds</h1>
          </div>
        </div>
        <div className="">Actions</div>
        {/* <Content /> */}
        <div className="">Footer</div>
      </div>
    </div>
  );
};

export default InvoiceSettingsWorkflowConfig;
