import IconThresholds from '@/assets/icons/icon-encircled-thresholds.svg';
import IconWorkflow from '@/assets/icons/icon-encircled-workflow.svg';
import CardSetting from '@/components/modules/InvoiceSettings/CardSetting';
import { JSX } from 'react';
import { useNavigate } from 'react-router-dom';

const InvoiceSettings = (): JSX.Element => {
  const navigate = useNavigate();
  const handleGoToConfidenceScore = (): void => navigate('/settings/confidence-score');
  const handleGoToWorkflowConfig = (): void => navigate('/settings/workflow-config');

  return (
    <div className="invoice-dashboard flex w-full">
      <div className="mx-10 flex w-full flex-col pt-5">
        <div className="flex w-full flex-col">
          <div>
            <h1 className="text-[44px] font-semibold">Invoice Management Settings</h1>
          </div>
          <div className="mt-12 flex">
            <CardSetting
              title="Confidence Score Thresholds"
              icon={IconThresholds}
              onClick={handleGoToConfidenceScore}
            />
            <CardSetting title="Workflow Configuration" icon={IconWorkflow} onClick={handleGoToWorkflowConfig} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSettings;
