import { IInvoiceWorkflowValues } from '@/model/invoice';
import { Button } from '@mui/material';
import { JSX, useEffect, useState } from 'react';
import { AccessConfiguration } from '@/constants/accessConfiguration.constants';
import { useAppDataContext } from '@/context';

type IProps = {
  onCancel: () => void;
  onSave: () => void;
  isDisabled: boolean;
  invoiceWorkflow: IInvoiceWorkflowValues[] | undefined;
};

const FooterActions = ({ onCancel, onSave, isDisabled, invoiceWorkflow }: IProps): JSX.Element | null => {
  const {
    state: { appPermissions },
    actions: { getUserAccessForResource }
  } = useAppDataContext();
  const [userAccess, setUserAccess] = useState<Record<string, Record<string, boolean>>>({
    footerDiscardOption: { write: false },
    footerSubmitOption: { write: false },
    footerApproveOption: { write: false },
    footerRejectOption: { write: false }
  });

  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      footerDiscardOption: getUserAccessForResource(AccessConfiguration.pageComponents.footer_discard_invoice),
      footerSubmitOption: getUserAccessForResource(AccessConfiguration.pageComponents.footer_submit_invoice),
      footerApproveOption: getUserAccessForResource(AccessConfiguration.pageComponents.footer_approve_invoice),
      footerRejectOption: getUserAccessForResource(AccessConfiguration.pageComponents.footer_reject_invoice)
    });
  }, [appPermissions]);
  return (
    <>
      {(invoiceWorkflow![1]?.action === 'Discard' && userAccess.footerDiscardOption?.write) ||
      (invoiceWorkflow![1]?.action === 'Reject' && userAccess.footerRejectOption?.write) ? (
        <Button variant={`text`} onClick={onCancel}>
          {invoiceWorkflow![1]?.action}
        </Button>
      ) : (
        ''
      )}
      {(invoiceWorkflow![0]?.action === 'Submit' && userAccess.footerSubmitOption?.write) ||
      (invoiceWorkflow![0]?.action === 'Approve' && userAccess.footerApproveOption?.write) ? (
        <Button variant={`contained`} onClick={onSave} disabled={isDisabled}>
          {invoiceWorkflow![0]?.action}
        </Button>
      ) : (
        ''
      )}
    </>
  );
};

export default FooterActions;
