import FilesLoaded from '@/components/modules/Common/FileUploads/FilesLoaded';
import { toast } from '@/components/ui';
import { BackdropBlur } from '@/components/ui/BackdropBlur/BackdropBlur';
import { TOAST_TIMEOUT } from '@/config/ui.config';
import uploadFiles from '@/hooks/useUploadFiles';
import { ChangeEvent, JSX, useEffect, useRef, useState } from 'react';
import { IFile, IFileUploadProps } from './types';
//@ts-ignore
import { Button } from "@aiops/styleguide";

interface IGetMessage {
  filesLength: number;
  messages: (string | undefined)[];
  fileType?: string | undefined;
  messageType: string;
}

export const getToastMessage = ({ filesLength, messages, fileType, messageType }: IGetMessage): string => {
  if (filesLength === 1 && !!messages[0] && fileType === "text/csv") return messages[0];
  return `${filesLength} ${messageType === "error" ? "invoices failed to upload." : "invoices uploaded successfully."}`;
};

export const getFailedFiles = (filesProcessed: IFile[]): IFile[] => {
  return filesProcessed.filter(file => file.error && !file.isCanceled);
};

export const getSuccessFiles = (filesProcessed: IFile[]): IFile[] => {
  return filesProcessed.filter(file => !file.error && !file.isCanceled);
};

export const getFailedFileMessage = (failedFiles: IFile[]): (string | undefined)[] => {
  return failedFiles.length !== 0 ? failedFiles.map(file => file.errorMessage) : [];
}

export const hideStatusFilesAfterNotify = (setShowStatusFiles: (show: boolean) => void) =>
  setTimeout(() => {
    setShowStatusFiles(false);
  }, TOAST_TIMEOUT);

const FileUpload = ({
  multiple,
  dragAndDrop,
  children,
  filesUploaded,
  setFilesUploaded,
  userName,
  onUploadComplete
}: IFileUploadProps): JSX.Element | null => {
  const [showStatusFiles, setShowStatusFiles] = useState<boolean>(true);
  const [isFileOpen, setIsFileOpen] = useState<boolean>(false);
  const propsFilesLoaded = { filesUploaded, showStatusFiles, setShowStatusFiles };
  const inputFile = useRef<HTMLInputElement>(null);


  const notify = (filesProcessed: IFile[]) => {
    const failedFiles = getFailedFiles(filesProcessed);
    const failedFileMessages: (string | undefined)[] = getFailedFileMessage(failedFiles);
    const successFiles = getSuccessFiles(filesProcessed);
    if (failedFiles.length) {
      toast(getToastMessage(
        {
          filesLength: failedFiles.length,
          messages: failedFileMessages,
          fileType: filesProcessed[0]?.type,
          messageType: "fail"
        }), 'error');
    }
    if (successFiles.length) {
      toast(getToastMessage(
        {
          filesLength: successFiles.length,
          messages: [filesProcessed[0]?.response],
          fileType: filesProcessed[0]?.type,
          messageType: "success"

        }), 'success');
      if (onUploadComplete) {
        onUploadComplete();
      }
    }
    hideStatusFilesAfterNotify(setShowStatusFiles);
  };

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { files }
    } = e;
    setIsFileOpen(false);
    uploadFiles(setFilesUploaded, notify)(files, userName);
  };

  const triggerBackdrop = () => {
    setIsFileOpen(true);
    window.addEventListener('focus', () => {
      setIsFileOpen(false);
      window.removeEventListener('focus', () => {});
    });
  };

  const triggerDragAndDrop = () => {
    /*logic to trigger drag and drop modal*/
  };

  useEffect(() => {
    setShowStatusFiles(true);

    return () => {};
  }, [filesUploaded]);

  return (
    <>
      {dragAndDrop && <Button onClick={triggerDragAndDrop}>{children}</Button>}
      {!dragAndDrop && (
        <>
          <input
            className="hidden"
            id="uploadFiles"
            ref={inputFile}
            type="file"
            multiple={multiple}
            name="files"
            onClick={triggerBackdrop}
            onChange={handleUpload}
          />
          <label htmlFor="uploadFiles" className="flex">
            {children}
          </label>
          {isFileOpen && <BackdropBlur />}
          {showStatusFiles && propsFilesLoaded.filesUploaded.length > 0 && <FilesLoaded {...propsFilesLoaded} />}
        </>
      )}
    </>
  );
};

export default FileUpload;
