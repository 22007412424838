import {
  IInvoiceLog,
  IInvoiceStatusWorkflow,
  IInvoicesPageFilter,
  IInvoicesSummary,
  Invoice,
  InvoiceDetails
} from '@/model/invoice';
import { Page } from '@/model/pagination';
import { AxiosProgressEvent } from 'axios';
import {
  getDownloadInvoice,
  getInvoiceDetailsRaw,
  getInvoiceDropDowns,
  getInvoiceLogsRaw,
  getInvoiceTemplate,
  getInvoiceWorkflowDecisionRaw,
  getInvoicesPageRaw,
  getInvoicesSummaryRaw,
  patchInvoiceDetailsRaw,
  postUploadInvoicesRaw,
  putInvoiceWorkflow
} from './api.requests';
import {
  mapToInvoiceDetails,
  mapToInvoiceLogs,
  mapToInvoicePatchRaw,
  mapToInvoicesPage,
  mapToInvoicesSummary,
  mapToItemsPatchRaw,
  mapToWorkflowDecision
} from './invoice.adapters';
import { IInvoicePatch, IInvoiceWorkflowDecisionRaw, IItemsSendPatch } from './invoice.types';

/**
 * Get the list of invoices paginated.
 *
 * @param {IInvoicesPageFilter} filter Filters like pagination, statuses, etc.
 * @returns {Promise<Page<Invoice>>} A promise that resolves with an array of invoices.
 */
export const getInvoices = async (filter: IInvoicesPageFilter): Promise<Page<Invoice>> => {
  const invoicesPageRaw = await getInvoicesPageRaw(filter);
  const invoicesPage = mapToInvoicesPage(invoicesPageRaw);
  return invoicesPage;
};

/**
 * Get an invoice with details.
 *
 * @param {string} documentNo The invoice ID, unique record identifier created for each invoice.
 * @returns {Promise<InvoiceDetails>} A promise that resolves with an invoice.
 */
export const getInvoice = async (documentNo: string): Promise<InvoiceDetails> => {
  const invoiceDetailsRaw = await getInvoiceDetailsRaw(documentNo);
  const invoiceDetails = mapToInvoiceDetails(invoiceDetailsRaw);
  return invoiceDetails;
};

/**
 * Get invoice CSV template.
 */
export const getTemplate = async (): Promise<any> => {
  const invoiceTemplateRaw = await getInvoiceTemplate()
  return invoiceTemplateRaw;
}

/**
 * Update an invoice.
 *
 * @desc PATCH the modified values to update the invoice.
 * @param {IInvoicePatch} invoice The invoice values to be updated.
 * @param {string} userName The full name of the user that updates the invoice.
 * @returns {Promise<[boolean, InvoiceDetails]>} A promise that resolves with a confirmation and the updated invoice.
 */
export const updateInvoice = async (invoice: IInvoicePatch, userName: string): Promise<[boolean, InvoiceDetails]> => {
  try {
    const invoiceRaw = mapToInvoicePatchRaw(invoice);
    const invoiceDetailsRawUpdated = await patchInvoiceDetailsRaw(invoiceRaw, userName);
    const invoiceDetailsUpdated = mapToInvoiceDetails(invoiceDetailsRawUpdated);
    const updateSuccess = invoiceRaw.document_no === invoiceDetailsRawUpdated.document_no;
    return [updateSuccess, invoiceDetailsUpdated];
  } catch (err) {
    throw new Error('Patch Invoice API JSON request failed.');
  }
};

/**
 * Update the invoice items.
 *
 * @desc PATCH the modified values of invoice items.
 * @param {IItemsSendPatch} invoiceItems The items values to be updated with Document number.
 * @param {string} userName The full name of the user that updates the invoice.
 * @returns {Promise<[boolean, InvoiceDetails]>} A promise that resolves with a confirmation and the updated invoice.
 */
export const updateInvoiceItems = async (
  invoiceItems: IItemsSendPatch,
  userName: string
): Promise<[boolean, InvoiceDetails]> => {
  try {
    const itemsPatchRaw = mapToItemsPatchRaw(invoiceItems);
    const invoiceDetailsRawUpdated = await patchInvoiceDetailsRaw(itemsPatchRaw, userName);
    const invoiceDetailsUpdated = mapToInvoiceDetails(invoiceDetailsRawUpdated);
    const updateSuccess = itemsPatchRaw.document_no === invoiceDetailsRawUpdated.document_no;
    return [updateSuccess, invoiceDetailsUpdated];
  } catch (err) {
    throw new Error('Patch Invoice Items API JSON request failed.');
  }
};

/**
 * Get invoice workflow decision.
 */
export const getInvoiceWorkflowDecision = async (): Promise<IInvoiceStatusWorkflow> => {
  try {
    const invoiceStatusWorkflowRaw = await getInvoiceWorkflowDecisionRaw();
    const invoiceStatusWorkflow = mapToWorkflowDecision(invoiceStatusWorkflowRaw);
    return invoiceStatusWorkflow;
  } catch (error) {
    throw new Error('Workflow API JSON request failed.');
  }
};

/**
 * Put invoice workflow decision.
 */
export const putInvoiceWorkflowDecision = async (
  documentNo: string,
  workflowDecision: IInvoiceWorkflowDecisionRaw,
  userName: string
): Promise<[boolean, any]> => {
  try {
    const msgLog = await putInvoiceWorkflow(documentNo, workflowDecision, userName);
    return [true, msgLog];
  } catch (err) {
    return [false, err];
  }
};

/**
 * Get the invoices summary.
 *
 * @returns {Promise<IInvoicesSummary>} A promise that resolves with the invoices summary.
 */
export const getInvoicesSummary = async (): Promise<IInvoicesSummary> => {
  const summaryRaw = await getInvoicesSummaryRaw();
  const summary = mapToInvoicesSummary(summaryRaw);
  return summary;
};

/**
 * Get invoice logs (history).
 *
 * @param {string} documentNo The invoice ID, unique record identifier created for each invoice.
 * @returns {Promise<IInvoiceLog[]>} A promise that resolves with the invoice logs or an empty array if no user intervention was applied.
 */
export const getInvoiceLogs = async (documentNo: string): Promise<IInvoiceLog[]> => {
  const invoiceLogsRaw = await getInvoiceLogsRaw(documentNo);
  const invoiceLogs = mapToInvoiceLogs(invoiceLogsRaw);
  return invoiceLogs;
};

/**
 * Upload an invoice.
 *
 * @param {file: File} uploadedFiles DOM file object if chosen files
 * @param {string} userName user uploading the file. Currently it is hardcoded.
 * @param {AbortSignal} abortSignal give ability to abort a file upload process
 * @param {onUploadProgress: AxiosProgressEvent} progressCallback provides progress of file upload
 * @returns {Promise<[boolean, any]>} A promise that resolves with the invoice uploaded
 */
export const postUploadInvoices = async (
  uploadedFiles: { file: File } | any,
  userName: string,
  abortSignal: AbortSignal,
  progressCallback?: { onUploadProgress: (progressEvent: AxiosProgressEvent) => void }
): Promise<[boolean, any]> => {
  try {
    const msgLog = await postUploadInvoicesRaw(uploadedFiles, userName, abortSignal, progressCallback);
    return [false, msgLog];
  } catch (err) {
    return [true, err];
  }
};

/**
 * Download and invoice.
 *
 * @param {string} documentNo The invoice ID, unique record identifier created for each invoice.
 * @returns {Promise<[boolean, any]>} A promise that resolves with the invoice downloaded
 */
export const downloadInvoice = async (documentNo: string): Promise<[boolean, any]> => {
  try {
    const downloadedInvoice = await getDownloadInvoice(documentNo);
    return [false, downloadedInvoice];
  } catch (err) {
    return [true, err];
  }
};

/**
 * Get dropdowns for invoice application.
 *
 * @param {string} params to get filtered list of invoice
 * @returns {Promise<[boolean, any]>} A promise that resolves with the invoice downloaded
 */
export const loadInvoiceDropDowns = async (params?: string): Promise<[boolean, any]> => {
  try {
    const dropdownResponse = await getInvoiceDropDowns(params);
    return [false, dropdownResponse];
  } catch (err) {
    return [true, err];
  }
};
