import { toast } from '@/components/ui';
import {
  IInvoiceActions,
  IInvoiceDropdowns,
  IInvoiceStatusWorkflow,
  IInvoiceWorkflowValues,
  InvoiceDetails,
  Item
} from '@/model/invoice';
import { loadInvoiceDropDowns } from '@/services';
import { JSX, useEffect, useMemo, useState } from 'react';
import { InvoiceDetailsContext } from './invoiceDetails.context';
import { IInvoiceDetailsContext, InvoiceLogs } from './invoiceDetails.types';

export const InvoiceDetailsProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  /* STATE */

  const [invoice, setInvoice] = useState<InvoiceDetails>({} as InvoiceDetails);
  const [invoiceItems, setInvoiceItems] = useState<Item[]>([]);
  const [invoiceLogs, setInvoiceLogs] = useState<InvoiceLogs>(undefined);
  const [valuesWorkflowDecision, setValuesWorkflowDecision] = useState<IInvoiceStatusWorkflow | undefined>(undefined);
  const [invoiceStatus, setInvoiceStatus] = useState<IInvoiceWorkflowValues[] | undefined>(undefined);
  const [showActions, setShowActions] = useState<IInvoiceActions | undefined>(undefined);
  const [invoiceIsReady, setInvoiceIsReady] = useState<boolean | undefined>(undefined);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [dropDowns, setDropDowns] = useState<IInvoiceDropdowns[]>([]);

  /* ACTIONS */

  const getDropdowns = async (): Promise<void> => {
    const [isError, dropdownResponse] = await loadInvoiceDropDowns();
    if (isError) toast('Error fetching dropdowns', 'error');
    else setDropDowns(dropdownResponse.data);
  };

  /* SIDE EFFECTS */

  useEffect(() => {
    getDropdowns();
  }, []);

  /* CONTEXT */

  const invoiceDetailsContext: IInvoiceDetailsContext = useMemo(
    () => ({
      state: {
        invoice,
        invoiceItems,
        showActions,
        valuesWorkflowDecision,
        invoiceStatus,
        invoiceLogs,
        invoiceIsReady,
        showModal,
        dropDowns
      },
      actions: {
        setInvoice,
        setInvoiceItems,
        setShowActions,
        setValuesWorkflowDecision,
        setInvoiceStatus,
        setInvoiceLogs,
        setInvoiceIsReady,
        setShowModal,
        setDropDowns
      }
    }),
    [
      invoice,
      invoiceItems,
      showActions,
      valuesWorkflowDecision,
      invoiceStatus,
      invoiceLogs,
      invoiceIsReady,
      showModal,
      dropDowns
    ]
  );

  return <InvoiceDetailsContext.Provider value={invoiceDetailsContext}>{children}</InvoiceDetailsContext.Provider>;
};
