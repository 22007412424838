import { JSX } from 'react';
import { ComponentProps } from './types';

const ErrorText = ({ customClasses, children, ...rest }: ComponentProps<'p'>): JSX.Element => {
  return (
    <p
      {...rest}
      className={`whitespace-nowrap text-left text-xs text-[var(--color-functional-negative-danger)] ${customClasses}`}
    >
      {children}
    </p>
  );
};

export default ErrorText;
