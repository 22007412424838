import IconArrowDown from '@/assets/icons/icon-arrow-down.svg';
import IconArrowUp from '@/assets/icons/icon-arrow-up.svg';
import IconClose from '@/assets/icons/icon-close.svg';
import { DialogModal } from '@/components/ui';
import { JSX, useState } from 'react';
import FileLoadedProgress from './FileLoadedProgress';
import './FilesLoaded.css';
import { IFile, IFilesLoadedProps } from './types';

const FilesLoaded = ({ filesUploaded, showStatusFiles, setShowStatusFiles }: IFilesLoadedProps): JSX.Element => {
  const [showLoadedFiles, setShowLoadedFiles] = useState<boolean>(true);
  const [cancelAllModal, setCancelAllModal] = useState<boolean>(false);
  const closeModal = (): void => setCancelAllModal(false);
  const openModal = (): void => setCancelAllModal(true);
  const handleShowLoadedFiles = (): void => setShowLoadedFiles((prev: boolean) => !prev);
  const handleShowStatusFiles = (): void => setShowStatusFiles((prev: boolean) => !prev);
  const countFilesInProgress = filesUploaded.filter((file: IFile) => file.inProgress).length;
  const displayStatusFiles = showStatusFiles ? 'absolute' : 'hidden';
  const displayLoadedFiles = showLoadedFiles ? 'flex' : 'hidden';

  const cancelFileUpload = (fileAbortControl: AbortController | undefined): void => {
    fileAbortControl?.abort();
  };

  const cancelInProgressFileUpload = (): void => {
    closeModal();
    filesUploaded.forEach(({ inProgress, abortControl }: IFile) => {
      if (inProgress) {
        cancelFileUpload(abortControl);
      }
    });
    handleShowStatusFiles();
  };

  const cancelAllModalConfig = {
    open: cancelAllModal,
    onClose: closeModal,
    headlineText: 'Are you sure you want to cancel the upload?',
    bodyText: `The ${countFilesInProgress} files that are still uploading will be canceled.`,
    cancelButtonText: 'No, continue',
    onCancel: closeModal,
    confirmationButtonText: 'Yes, cancel upload',
    onConfirm: cancelInProgressFileUpload
  };

  return (
    <div className={`${displayStatusFiles} s2pim-files-loaded`}>
      {/* Header */}
      <div className="flex h-9 items-center justify-between rounded-t bg-[var(--color-secondary-youngfig)] px-6 py-1">
        <div className="text-sm">
          <p>Uploads</p>
        </div>
        <div className="files-uploaded-btns">
          <button
            type="button"
            onClick={handleShowLoadedFiles}
            children={<img src={showLoadedFiles ? IconArrowUp : IconArrowDown} alt="toggle for file loader" />}
          />
          <button
            type="button"
            onClick={(): void => (countFilesInProgress ? openModal() : handleShowStatusFiles())}
            children={<img src={IconClose} alt="close for file loader" />}
          />
          {cancelAllModal && countFilesInProgress > 0 && <DialogModal {...cancelAllModalConfig} />}
        </div>
      </div>
      {/* File´s progress */}
      <div className={`${displayLoadedFiles} files-uploaded-description`}>
        {filesUploaded.map((file: IFile) => (
          <FileLoadedProgress key={`file-${file.name}`} fileInfo={file} />
        ))}
      </div>
    </div>
  );
};

export default FilesLoaded;
