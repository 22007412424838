import { IFile } from '@/components/modules/Common/FileUploads/types';
import { useStorage } from '@/hooks/useStorage';
import { IInvoicesPageFilter, IInvoicesSummary, Invoice } from '@/model/invoice';
import { Page } from '@/model/pagination';
import { getInvoices, getInvoicesSummary } from '@/services';
import { mapToPageFilterRawSt, mapToPageFilterSt } from '@/services/invoice.adapters';
import { JSX, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_INVOICES_PAGE,
  DEFAULT_INVOICES_PAGE_FILTER,
  DEFAULT_INVOICES_SUMMARY,
  InvoiceDashboardContext
} from './invoiceDashboard.context';
import { IInvoiceDashboardContext } from './invoiceDashboard.types';

export const InvoiceDashboardProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  /* STATE */

  const [isOpenInvoicesSummary, setIsOpenInvoiceSummary] = useState<boolean>(false);
  const [isLoadingInvoicesSummary, setIsLoadingInvoiceSummary] = useState<boolean>(false);
  const [isLoadingInvoicesTable, setIsLoadingInvoiceTable] = useState<boolean>(false);
  const [invoicesSummary, setInvoicesSummary] = useState<IInvoicesSummary>(DEFAULT_INVOICES_SUMMARY);
  const [invoicesPage, setInvoicesPage] = useState<Page<Invoice>>(DEFAULT_INVOICES_PAGE);
  // const [invoicesPageFilter, setInvoicesPageFilter] = useState<IInvoicesPageFilter>(DEFAULT_INVOICES_PAGE_FILTER);
  const [invoicesPageFilter, setInvoicesPageFilter] = useStorage<IInvoicesPageFilter>(
    sessionStorage,
    'invoices-table_page-filter',
    DEFAULT_INVOICES_PAGE_FILTER,
    mapToPageFilterRawSt,
    mapToPageFilterSt
  );
  const [filesUploaded, setFilesUploaded] = useState<IFile[]>([]);

  /* ACTIONS */

  const toggleOpenInvoicesSummary = (): void => setIsOpenInvoiceSummary(state => !state);

  const resetSorting = (): void => {
    setInvoicesPageFilter({
      ...invoicesPageFilter,
      sorting: new Set()
    });
  };

  const updateInvoicesSummary = async (): Promise<void> => {
    setIsLoadingInvoiceSummary(true);
    try {
      setInvoicesSummary(await getInvoicesSummary());
    } catch (e) {
      setInvoicesSummary(DEFAULT_INVOICES_SUMMARY);
    }
    setIsLoadingInvoiceSummary(false);
  };

  const updateInvoicesTable = async (): Promise<void> => {
    setIsLoadingInvoiceTable(true);
    try {
      setInvoicesPage(await getInvoices(invoicesPageFilter));
    } catch (e) {
      setInvoicesPage(DEFAULT_INVOICES_PAGE);
    }
    setIsLoadingInvoiceTable(false);
  };

  const updateDashboard = async (): Promise<void> => {
    isOpenInvoicesSummary ? updateInvoicesSummary() : setInvoicesSummary(DEFAULT_INVOICES_SUMMARY);
    updateInvoicesTable();
  };

  /* SIDE EFFECTS */

  useEffect(() => {
    if (isOpenInvoicesSummary) {
      updateInvoicesSummary();
    }
  }, [isOpenInvoicesSummary]);

  useEffect(() => {
    updateDashboard();
  }, [invoicesPageFilter]);

  /* CONTEXT */

  const invoiceDashboardContext: IInvoiceDashboardContext = useMemo(
    () => ({
      state: {
        isOpenInvoicesSummary,
        isLoadingInvoicesSummary,
        isLoadingInvoicesTable,
        invoicesSummary,
        invoicesPage,
        invoicesPageFilter,
        filesUploaded
      },
      actions: {
        toggleOpenInvoicesSummary,
        setIsLoadingInvoiceSummary,
        setIsLoadingInvoiceTable,
        setInvoicesSummary,
        setInvoicesPage,
        setInvoicesPageFilter,
        setFilesUploaded,
        resetSorting,
        updateDashboard
      }
    }),
    [isLoadingInvoicesSummary, isLoadingInvoicesTable, invoicesSummary, invoicesPage, invoicesPageFilter, filesUploaded]
  );

  return (
    <InvoiceDashboardContext.Provider value={invoiceDashboardContext}>{children}</InvoiceDashboardContext.Provider>
  );
};
