import { Sorting } from './sorting';

export type DetailedValue = {
  confidence?: number;
  edited?: boolean;
  exceptions?: boolean;
};

export type DetailedStringValue = DetailedValue & {
  value?: string;
};

export type DetailedNumberValue = DetailedValue & {
  value?: number;
};

export type DetailedDateValue = DetailedValue & {
  value?: Date;
};

export type DetailedPriorityValue = DetailedValue & {
  value?: InvoicePriority;
};

export type DetailedAddressValue = DetailedValue & {
  value?: {
    address: string;
    normalizedAddress: string;
    addressDetails: {
      regionCode: string;
      languageCode: string;
      postalCode: string;
      administrativeArea: string;
      locality: string;
      addressLines: string[];
    };
  };
};

export type Item = {
  itemNo: number;
  description: DetailedStringValue;
  quantity: DetailedNumberValue;
  unitPrice: DetailedNumberValue;
  amount: DetailedNumberValue;
  glAccount: DetailedStringValue;
  exceptionCount: number;
};

export type Invoice = {
  documentNo: string;
  invoiceNo: DetailedStringValue;
  documentDate: Date;
  invoiceDate: DetailedDateValue;
  dueDate: DetailedDateValue;
  businessUnit: DetailedStringValue;
  supplier: DetailedStringValue;
  supplierId: DetailedStringValue;
  grossAmount: DetailedNumberValue;
  netAmount: DetailedNumberValue;
  totalTaxAmount: DetailedNumberValue;
  currency: DetailedStringValue;
  paymentTerms: DetailedStringValue;
  submitter: DetailedStringValue;
  requester: DetailedStringValue;
  status: InvoiceStatus;
  priority: DetailedPriorityValue;
  fileType: string;
  type: string;
  isPotentiallyDuplicated: boolean;
  potentiallyDuplicateOfDocumentNo: string;
};

export type InvoiceDetails = Invoice & {
  supplierAddress: DetailedAddressValue;
  items: Item[];
  exceptions: IInvoiceExceptions;
};

export interface IInvoicesSummary {
  total: number;

  totalPendingReview: number;
  totalReadyToSubmit: number;
  totalPendingApproval: number;

  totalAge1to5: number;
  totalAge6to10: number;
  totalAge11to15: number;
  totalAge16plus: number;
  totalAgeUndefined: number;

  totalPastDue: number;
  totalDueInLessThan3Days: number;
  totalDueIn3To5Days: number;
  totalUndefined: number;
}

export interface InvoiceFieldSorting {
  field: keyof Invoice;
  order: Sorting;
}

export interface IInvoicesPageFilter {
  page: number;
  pageSize: number;
  statuses: Set<InvoiceStatus>;
  sorting: Set<InvoiceFieldSorting>;
  searchText: string;
}

export interface IInvoiceLog {
  action: string;
  updatedAt: Date;
  userName: string;
  comment: string;
  rejectionReason?: string;
}

export interface IInvoiceActions {
  hasEditActions: boolean;
  hasWorkflowActions: boolean;
}

export interface IInvoiceWorkflowValues {
  current: string;
  action: string;
  result: string;
  activation: string;
}

export interface IInvoiceStatusWorkflow {
  statusFlows: IInvoiceWorkflowValues[];
}

export interface IInvoiceExceptions {
  totalItemExceptions: number;
  totalExceptions: number;
}

export interface IInvoiceDropdowns {
  field: 'payment_terms' | 'payment_method' | 'invoice_priority' | 'rejection_reason';
  values: string[];
}

export enum InvoiceStatus {
  PendingReview = 'Pending Review',
  Discarded = 'Discarded',
  ReadyToSubmit = 'Ready to Submit',
  PendingApproval = 'Pending Approval',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Posted = 'Posted',
  PostingError = 'Posting Error',
  Paid = 'Paid',
  PaidExternally = 'Paid externally',
  Unpaid = 'Unpaid',
  Void = 'Void',
  Reconciled = 'Reconciled',
  Paying = 'Paying',
  Canceled = 'Canceled',
  Processing = 'Processing',
  Unknown = 'Unknown'
}

export enum InvoicePriority {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  Unknown = 'Unknown'
}
