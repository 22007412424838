import { JSX } from 'react';
import { ICardSettingProps } from './types';
//@ts-ignore
import { Button } from "@aiops/styleguide";

const CardSetting = ({ title, icon, onClick }: ICardSettingProps): JSX.Element => {
  return (
    <Button
      onClick={onClick}
      className={
        's2pim-card-animated mr-3 flex h-[176px] w-[298px] cursor-pointer flex-col items-center rounded-2xl border border-[var(--color-secondary-sadlilac)] px-3 pt-8 text-center text-xl font-light'
      }
    >
      <img src={icon} alt="Icon" className="s2pim-svg-sadlilac mb-4" />
      <p>{title}</p>
    </Button>
  );
};

export default CardSetting;
