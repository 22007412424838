import { AxiosError } from 'axios';

export const getFile = (
  apiCall: (params: any) => Promise<any>,
  errorCallBack?: ((error: AxiosError) => void) | undefined,
  successCallBack?: (() => void) | undefined
) => {
  return async (params: string): Promise<void> => {
    const [isError, response]: any = await apiCall(params);
    if (isError) {
      if (errorCallBack) {
        errorCallBack(response);
      }
    } else {
      const filename = response.headers
        .get('content-disposition')
        .split(';')
        .find((n: any) => n.includes('filename='))
        .replace('filename=', '')
        .trim();
      const data = new Blob([response.data as ArrayBuffer], { type: response.headers.get('content-type') });
      const pdfURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = pdfURL;
      tempLink.setAttribute('download', filename);
      tempLink.click();
      if (successCallBack) {
        successCallBack();
      }
    }
  };
};
