import { JSX } from 'react';
import { IGraphInvoicesByAgeProps } from './types';

const GraphInvoicesByAge = ({
  totalAge1to5 = 0,
  totalAge6to10 = 0,
  totalAge11to15 = 0,
  totalAge16plus = 0,
  totalAgeUndefined = 0
}: IGraphInvoicesByAgeProps): JSX.Element => {
  const totalMax = Math.max(totalAge1to5, totalAge6to10, totalAge11to15, totalAge16plus);
  const percentAge1to5 = Math.round((totalAge1to5 * 100) / totalMax);
  const percentAge6to10 = Math.round((totalAge6to10 * 100) / totalMax);
  const percentAge11to15 = Math.round((totalAge11to15 * 100) / totalMax);
  const percentAge16plus = Math.round((totalAge16plus * 100) / totalMax);
  const percentAgeUndefined = Math.round((totalAgeUndefined * 100) / totalMax);

  return (
    <div className="flex flex-col">
      <h2 className="mb-1 text-sm font-bold">Open Invoices by Age</h2>

      <ul className="my-auto text-xs text-[var(--color-secondary-sadlilac)]">
        <li className="flex h-8 w-80 items-center">
          <div className="w-20">1-5 Days</div>
          <div className="h-2 w-48 items-center">
            <div
              className={`h-full rounded-full bg-[var(--color-dashboard-days-va)]`}
              style={{ width: `${percentAge1to5}%` }}
            ></div>
          </div>
          <div data-testid="graph-invoices-age-1-to-5" className="ml-2 font-bold">
            {totalAge1to5}
          </div>
        </li>
        <li className="flex h-8 w-80 items-center">
          <div className="w-20">6-10 Days</div>
          <div className="h-2 w-48 items-center">
            <div
              className={`h-full rounded-full bg-[var(--color-dashboard-days-vb)]`}
              style={{ width: `${percentAge6to10}%` }}
            ></div>
          </div>
          <div data-testid="graph-invoices-age-6-to-10" className="ml-2 font-bold">
            {totalAge6to10}
          </div>
        </li>
        <li className="flex h-8 w-80 items-center">
          <div className="w-20">11-15 Days</div>
          <div className="h-2 w-48 items-center">
            <div
              className={`h-full rounded-full bg-[var(--color-dashboard-days-vc)]`}
              style={{ width: `${percentAge11to15}%` }}
            ></div>
          </div>
          <div data-testid="graph-invoices-age-11-to-15" className="ml-2 font-bold">
            {totalAge11to15}
          </div>
        </li>
        <li className="flex h-8 w-80 items-center">
          <div className="w-20">15+ Days</div>
          <div className="h-2 w-48 items-center">
            <div
              className={`h-full rounded-full bg-[var(--color-dashboard-days-vd)]`}
              style={{ width: `${percentAge16plus}%` }}
            ></div>
          </div>
          <div data-testid="graph-invoices-age-16-plus" className="ml-2 font-bold">
            {totalAge16plus}
          </div>
        </li>
        {/* <li className="flex h-8 w-80 items-center">
          <div className="w-20">Undefined</div>
          <div className="h-2 w-48 items-center">
            <div
              className={`h-full rounded-full bg-[var(--color-primary-hibiscus)]`}
              style={{ width: `${percentAgeUndefined}%` }}
            ></div>
          </div>
          <div data-testid="graph-invoices-age-undefined" className="ml-2 font-bold">
            {totalAgeUndefined}
          </div>
        </li> */}
      </ul>
    </div>
  );
};

export default GraphInvoicesByAge;
