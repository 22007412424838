import { JSX } from 'react';
import './Button.css';
import { IButton } from './types';

const Button = ({
  type = 'button',
  variant = 'text',
  value,
  onClick,
  styles = '',
  icon,
  ...props
}: IButton): JSX.Element => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`s2pim-invoice-details-btn ${variant}-variant ${styles}`}
      {...props}
    >
      {icon && <img src={icon} alt="Icon" className="h-[17.04px] w-[17.04px]" />}
      <p>{value}</p>
    </button>
  );
};

export default Button;
