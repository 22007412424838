import { IInvoiceLog } from '@/model/invoice';
import format from 'date-fns/format';
import { JSX } from 'react';
import Log from './Log';
import './Log.css';

const CardLogs = ({ action, updatedAt, userName, comment, rejectionReason }: IInvoiceLog): JSX.Element => {
  return (
    <div className="s2p-details-card-log-container">
      <div className="s2p-details-card-log-box">
        <Log title="User" data={userName} />
        <Log title="Date" data={format(updatedAt, 'MM/dd/yyyy')} />
      </div>
      <div className="s2p-details-card-log-box">
        <Log title="Action" data={action} />
        {rejectionReason && <Log title="Rejection Reason" data={rejectionReason} />}
        {comment && (
          <>
            <p className="w-full text-xs font-normal">Details:</p>
            <p className="w-full text-xs font-normal break-all">{comment}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default CardLogs;
