import IconAlert from '@/assets/icons/icon-alert.svg';
import IconClose from '@/assets/icons/icon-close.svg';
import { JSX } from 'react';
import './InlineBadge.css';
import { IInlineBadgeProps } from './types';

const InlineBadge = ({ variant = 'default', className }: IInlineBadgeProps): JSX.Element => {
  let icon: string;
  switch (variant) {
    case 'warning':
      icon = IconAlert;
      break;
    case 'error':
      icon = IconClose;
      break;
    default:
      icon = IconAlert;
  }

  return (
    <div className={`s2pim-inline-badge s2pim-inline-badge--${variant} h-4 w-4 rounded-full ${className}`}>
      <img src={icon} alt="Badge" className="mx-auto" />
    </div>
  );
};

export default InlineBadge;
