import { Tooltip } from '@/components/ui';
import { JSX } from 'react';
import { useFilesLoadedContext } from './FilesLoadedContext';
import './ProgressBar.css';

const ProgressBar = (): JSX.Element => {
  const { error, response, progress, inProgress, isCanceled } = useFilesLoadedContext();
  const colorFinalStatus = !inProgress && error ? 'progress-error' : 'progress-completed';
  const colorProgressBar = inProgress ? 'progress-loading' : colorFinalStatus;

  if (isCanceled) return <div className="progress-bar canceled-indicator" />;

  if (error)
    return (
      <Tooltip content={response || 'Error'} variant="error">
        <div className="progress-bar">
          <div style={{ width: `${progress}%` }} className="progress-bar-indicator progress-error " />
        </div>
      </Tooltip>
    );
  return (
    <div className="progress-bar">
      <div style={{ width: `${progress}%` }} className={`progress-bar-indicator ${colorProgressBar}`} />
    </div>
  );
};

export default ProgressBar;
