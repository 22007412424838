import axios from 'axios';

const axiosConfig = {
  //baseURL: `https://${API_SERVER}/${API_BASE}`,
  //headers: { Authorization: `Bearer ${token}` }
};

const axiosInstance = axios.create(axiosConfig);

const setBaseUrl = (baseURL: string): void => {
  axiosInstance.defaults.baseURL = baseURL;
};

const setAuthToken = (authToken: string): void => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
};

export { axiosInstance, setAuthToken, setBaseUrl };
