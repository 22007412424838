import IconError from '@/assets/icons/icon-toast-error.svg';
import IconSuccess from '@/assets/icons/icon-toast-success.svg';
import IconWarning from '@/assets/icons/icon-toast-warning.svg';
import { TOAST_TIMEOUT } from '@/config/ui.config';
import { JSX } from 'react';
import { Slide, ToastContainer as ToastifyContainer, toast as toastify } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.css';
import { IToastProps, ToastVariants } from './types';

const ToastContainer = ({ timeout = TOAST_TIMEOUT, className }: IToastProps) => {
  return (
    <div data-testid="ToastifyContainer" className={`s2pim-toast ${className ? className : ''}`}>
      <ToastifyContainer
        position="top-center"
        autoClose={timeout}
        hideProgressBar
        pauseOnHover
        pauseOnFocusLoss
        closeOnClick={false}
        newestOnTop={false}
        rtl={false}
        draggable={false}
        transition={Slide}
      />
    </div>
  );
};

export const toast = (content: string | JSX.Element, variant?: ToastVariants, timeout?: number): void => {
  let icon: any;
  switch (variant) {
    case 'success':
      icon = () => <img src={IconSuccess} alt="IconSuccess" />;
      break;
    case 'warning':
      icon = () => <img src={IconWarning} alt="IconWarning" />;
      break;
    case 'error':
      icon = () => <img src={IconError} alt="IconError" />;
      break;
    default:
      icon = false;
  }

  toastify(content, {
    icon: icon,
    autoClose: timeout,
    className: `s2pim-toast--${variant}`
  });
};

export default ToastContainer;
