import { DatePickerFormik, DropDown } from '@/components/ui';
import { IDropDown } from '@/components/ui/DropDown/DropDown';
import { IInvoiceDropdowns } from '@/model/invoice';
import { getIn } from 'formik';
import { JSX, useRef } from 'react';
import Input from '../Input/Input';
import { parseDetailValue } from '../utils/parseDetailValue';
import { showConfidenceOrUnknownValues } from '../utils/showConfidenceOrUnknownValues';
import DetailValue from './DetailValue';
import ErrorText from './ErrorText';
import './ManagerFieldsRender.css';
import { EditorInvoiceProps } from './types';

const ManagerFieldsRender = ({
  field,
  form,
  isEdit,
  meta,
  isEditable,
  inputPlaceholder,
  dropDowns,
  maxLength
}: EditorInvoiceProps): JSX.Element => {
  const { name, value: invoiceFieldValue } = field;
  const { errors, handleBlur, setValues, values } = form;
  const invoiceValueParsed = invoiceFieldValue && parseDetailValue(invoiceFieldValue, name, isEdit);
  const invoiceValueError = getIn(errors[name], 'value');
  const hasDropDown = (inputName: string): boolean =>
    ['paymentTerms', 'priority', 'currency'].some((value: string) => value === inputName);
  const dropDownRef = useRef<HTMLInputElement>(null);
  const dropDownsPairs: any = { paymentTerms: 'payment_terms', priority: 'invoice_priority', currency: 'currency' };
  const getDropDownsValues = (): any => {
    const dropDownType: string = dropDownsPairs[name];
    return dropDowns?.filter((dropDownList: IInvoiceDropdowns) => dropDownList.field === dropDownType);
  };

  const setDropDownValue = (selectedOption: string): void => {
    return setValues({
      ...values,
      [name]: {
        ...values[name],
        ['value']: selectedOption
      }
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    return setValues({
      ...values,
      [name]: {
        ...values[name],
        ['value']: e.target.value
      }
    });
  };
  const dropDownConfig: IDropDown = {
    dropDownRef: dropDownRef,
    optionList: getDropDownsValues()[0]?.values,
    placeHolder: String(invoiceValueParsed || inputPlaceholder),
    valueSelected: setDropDownValue
  };

  if (!isEdit || !isEditable)
    return <DetailValue field={field} form={form} meta={meta} children={invoiceValueParsed} />;
  if (hasDropDown(name)) {
    return (
      <>
        <DropDown key={`${name}-dropDown`} {...dropDownConfig} />
        {invoiceValueError && <ErrorText children={invoiceValueError} customClasses="error-item" />}
        <div className="batch-item flex">{showConfidenceOrUnknownValues(name, invoiceFieldValue)}</div>
      </>
    );
  }

  if (name === 'invoiceDate') return <DatePickerFormik {...field} {...form} />;
  return (
    <>
      <Input
        id={name}
        key={name}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder={inputPlaceholder}
        value={invoiceValueParsed}
        aria-invalid={invoiceValueError ? true : false}
        customClasses="input-item"
        maxLength={maxLength}
      />
      {invoiceValueError && <ErrorText children={invoiceValueError} customClasses="error-item" />}
      <div className="batch-item flex">{showConfidenceOrUnknownValues(name, invoiceFieldValue)}</div>
    </>
  );
};

export default ManagerFieldsRender;
