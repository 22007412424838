import { useAppDataContext, useInvoiceDetailsContext } from '@/context';
import { InvoiceDetails } from '@/model/invoice';
import { updateInvoice } from '@/services';
import { Formik } from 'formik';
import { JSX } from 'react';
import { object } from 'yup';
import { DETAILS_VALIDATION_SCHEMA } from '../constants/invoice-validation';
import { getChangedValuesInvoiceOrItems } from '../utils/getChangedValuesInvoiceOrItems';
import DetailsForm from './DetailsForm';
import './ViewDetail.css';

const ViewDetail = (): JSX.Element => {
  const {
    state: { invoice },
    actions: { setInvoice }
  } = useInvoiceDetailsContext();
  const validationSchema = object().shape({ ...DETAILS_VALIDATION_SCHEMA });
  const {
    state: { user }
  } = useAppDataContext();

  const handleSubmit = async (formValues: InvoiceDetails, resetForm: (...args: any[]) => void): Promise<void> => {
    const valuesToPatch = {
      documentNo: formValues.documentNo,
      ...getChangedValuesInvoiceOrItems(invoice, formValues)
    };

    const [updateSuccess, invoiceDetailsUpdated] = await updateInvoice(valuesToPatch, user.name);

    if (updateSuccess) {
      setInvoice(invoiceDetailsUpdated);
    } else {
      resetForm();
    }
  };

  return (
    <>
      <Formik
        initialValues={invoice}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(invoice, { resetForm }) => handleSubmit(invoice, resetForm)}
        validateOnMount
      >
        {() => <DetailsForm />}
      </Formik>
    </>
  );
};

export default ViewDetail;
