import { Invoice, Item } from '@/model/invoice';
import { IInvoicePatch, IItemPatch } from '@/services/invoice.types';

export const getChangedValuesInvoiceOrItems = <T extends Invoice | Item, Y extends Invoice | Item>(
  initialValues: T,
  valuesToCompare: Y
): IInvoicePatch | IItemPatch => {
  const updatePatch = Object.entries(valuesToCompare).reduce((acc, [key, value]) => {
    const hasChanged = initialValues[key as keyof T] !== value;
    if (hasChanged) {
      acc = {
        ...acc,
        [key]: typeof value === 'object' ? value.value : value
      };
    }
    return acc;
  }, {});
  return updatePatch;
};
