import transformValuesKeysToRaw from '@/components/modules/InvoiceDetails/utils/transformValuesKeysToRaw';
import {
  DetailedAddressValue,
  DetailedDateValue,
  DetailedNumberValue,
  DetailedPriorityValue,
  DetailedStringValue,
  DetailedValue,
  IInvoiceExceptions,
  IInvoiceLog,
  IInvoiceStatusWorkflow,
  IInvoiceWorkflowValues,
  IInvoicesPageFilter,
  IInvoicesSummary,
  Invoice,
  InvoiceDetails,
  InvoiceFieldSorting,
  InvoicePriority,
  InvoiceStatus,
  Item
} from '@/model/invoice';
import { Page } from '@/model/pagination';
import { Sorting } from '@/model/sorting';
import { isValidNumber } from '@/utils';
import {
  IDetailedAddressValueRaw,
  IDetailedNumberValueRaw,
  IDetailedStringValueRaw,
  IDetailedValueRaw,
  IInvoiceDetailsRaw,
  IInvoiceExceptionsRaw,
  IInvoiceLogRaw,
  IInvoiceLogsRaw,
  IInvoicePatch,
  IInvoicePatchRaw,
  IInvoiceRaw,
  IInvoiceWorkflowRaw,
  IInvoiceWorkflowValuesRaw,
  IInvoicesPageFilterRaw,
  IInvoicesPageRaw,
  IInvoicesSummaryRaw,
  IItemPatchRaw,
  IItemRaw,
  IItemsSendPatch,
  IItemsSendPatchRaw
} from './invoice.types';

////////////////////////////////////////////////////////////////////////////////
// FROM API TO MODEL
////////////////////////////////////////////////////////////////////////////////

/**
 * Detailed Values Adapters
 */
const mapToDetailedValueBase = (detailedValueRaw: IDetailedValueRaw): DetailedValue => {
  return {
    confidence: detailedValueRaw.confidence,
    edited: detailedValueRaw.edited_by_user,
    exceptions: detailedValueRaw.exceptions
  };
};

const mapToDetailedStringValue = (detailedValueRaw: IDetailedStringValueRaw): DetailedStringValue => {
  if (!detailedValueRaw?.attribute_value) return {};
  return {
    ...mapToDetailedValueBase(detailedValueRaw),
    value: detailedValueRaw.attribute_value
  };
};

const mapToDetailedNumberValue = (detailedValueRaw: IDetailedNumberValueRaw): DetailedNumberValue => {
  if (!detailedValueRaw?.attribute_value || !isValidNumber(detailedValueRaw.attribute_value)) return {};
  return {
    ...mapToDetailedValueBase(detailedValueRaw),
    value: detailedValueRaw.attribute_value
  };
};

const mapToDetailedDateValue = (detailedValueRaw: IDetailedStringValueRaw): DetailedDateValue => {
  if (!detailedValueRaw?.attribute_value) return {};
  return {
    ...mapToDetailedValueBase(detailedValueRaw),
    value: new Date(detailedValueRaw.attribute_value)
  };
};

const mapToDetailedPriorityValue = (detailedValueRaw: IDetailedStringValueRaw): DetailedPriorityValue => {
  if (!detailedValueRaw?.attribute_value) return {};
  return {
    ...mapToDetailedValueBase(detailedValueRaw),
    value:
      Object.values(InvoicePriority)[
        Object.values(InvoicePriority).indexOf(detailedValueRaw.attribute_value as InvoicePriority)
      ] || InvoicePriority.Unknown
  };
};

const mapToDetailedAddressValue = (detailedValueRaw: IDetailedAddressValueRaw): DetailedAddressValue => {
  if (!detailedValueRaw?.attribute_value) return {};
  const addressRaw = detailedValueRaw.attribute_value;
  return {
    ...mapToDetailedValueBase(detailedValueRaw),
    value: {
      address: addressRaw.value,
      normalizedAddress: addressRaw.normalizedValue,
      addressDetails: {
        regionCode: addressRaw.address_details?.region_code,
        languageCode: addressRaw.address_details?.language_code,
        postalCode: addressRaw.address_details?.postal_code,
        administrativeArea: addressRaw.address_details?.administrative_area,
        locality: addressRaw.address_details?.locality,
        addressLines: addressRaw.address_details?.address_lines?.map(line => line)
      }
    }
  };
};

const mapToInvoiceStatus = (invoiceStatusRaw: string): InvoiceStatus => {
  return (
    Object.values(InvoiceStatus)[Object.values(InvoiceStatus).indexOf(invoiceStatusRaw as InvoiceStatus)] ||
    InvoiceStatus.Unknown
  );
};

const mapToInvoiceExceptions = (exceptionsValuesRaw: IInvoiceExceptionsRaw): IInvoiceExceptions => {
  return {
    totalExceptions: exceptionsValuesRaw.total_exceptions,
    totalItemExceptions: exceptionsValuesRaw.total_item_exceptions
  };
};

const mapToDetailedWorkflowValues = (statusRaw: IInvoiceWorkflowValuesRaw): IInvoiceWorkflowValues => {
  return {
    current: statusRaw.current_status,
    action: statusRaw.possible_action,
    result: statusRaw.resulting_status,
    activation: statusRaw.activation_criteria
  };
};

/**
 * Item Adapter
 *
 * @param {IItemRaw} itemRaw Item data from the API.
 * @returns {Item} Item model.
 */
const mapToItem = (itemRaw: IItemRaw): Item => {
  return {
    itemNo: itemRaw.item_number,
    description: mapToDetailedStringValue(itemRaw.description),
    quantity: mapToDetailedNumberValue(itemRaw.quantity),
    unitPrice: mapToDetailedNumberValue(itemRaw.unit_price),
    amount: mapToDetailedNumberValue(itemRaw.amount),
    glAccount: mapToDetailedStringValue(itemRaw.gl_account),
    exceptionCount: itemRaw.exception_count
  };
};

/**
 * Invoice Adapter
 *
 * @param {IInvoiceRaw} invoiceRaw Invoice data from the API.
 * @returns {Invoice} Invoice model.
 */
export const mapToInvoice = (invoiceRaw: IInvoiceRaw): Invoice => {
  try {
    return {
      documentNo: invoiceRaw.document_no,
      invoiceNo: mapToDetailedStringValue(invoiceRaw.invoice_no),
      documentDate: new Date(invoiceRaw.doc_date),
      invoiceDate: mapToDetailedDateValue(invoiceRaw.invoice_date),
      dueDate: mapToDetailedDateValue(invoiceRaw.due_date),
      businessUnit: mapToDetailedStringValue(invoiceRaw.business_unit),
      supplier: mapToDetailedStringValue(invoiceRaw.supplier),
      supplierId: mapToDetailedStringValue(invoiceRaw.supplier_id),
      grossAmount: mapToDetailedNumberValue(invoiceRaw.gross_amt),
      netAmount: mapToDetailedNumberValue(invoiceRaw.net_amt),
      totalTaxAmount: mapToDetailedNumberValue(invoiceRaw.total_tax_amount),
      currency: mapToDetailedStringValue(invoiceRaw.currency),
      paymentTerms: mapToDetailedStringValue(invoiceRaw.pymt_terms),
      submitter: mapToDetailedStringValue(invoiceRaw.submitter),
      requester: mapToDetailedStringValue(invoiceRaw.requestor),
      status: mapToInvoiceStatus(invoiceRaw.invoice_status),
      priority: mapToDetailedPriorityValue(invoiceRaw.invoice_priority),
      type: invoiceRaw.type,
      fileType: invoiceRaw.file_type,
      isPotentiallyDuplicated: invoiceRaw.is_potentially_duplicated,
      potentiallyDuplicateOfDocumentNo: invoiceRaw.potentially_duplicate_of_document_no
    };
  } catch (err) {
    throw new Error('Invoice API JSON adapter failed.');
  }
};

/**
 * Invoice Details Adapter
 *
 * @param {IInvoiceDetailsRaw} invoiceDetailsRaw Invoice with details data from the API.
 * @returns {InvoiceDetails} Invoice with details model.
 */
export const mapToInvoiceDetails = (invoiceDetailsRaw: IInvoiceDetailsRaw): InvoiceDetails => {
  try {
    return {
      ...mapToInvoice(invoiceDetailsRaw),
      supplierAddress: mapToDetailedAddressValue(invoiceDetailsRaw.supplier_address),
      items: invoiceDetailsRaw.items.map(itemRaw => mapToItem(itemRaw)),
      exceptions: mapToInvoiceExceptions(invoiceDetailsRaw.exceptions)
    };
  } catch (err) {
    throw new Error('Invoice Details API JSON adapter failed.');
  }
};

/**
 * Invoices List Adapter
 *
 * @param {IInvoicesPageRaw} invoicesPageRaw List of invoices data from the API.
 * @returns {Page<Invoice>} List of invoices paginated model.
 */
export const mapToInvoicesPage = (invoicesPageRaw: IInvoicesPageRaw): Page<Invoice> => {
  try {
    const invoices = invoicesPageRaw.invoices.map(invoiceRaw => mapToInvoice(invoiceRaw));

    return {
      data: invoices,
      page: invoicesPageRaw.page,
      size: invoicesPageRaw.page_size,
      total: invoicesPageRaw.total_results
    };
  } catch (err) {
    throw new Error('Invoices API JSON adapter failed.');
  }
};

/**
 * Invoice status list Adapter
 *
 * @param {IInvoiceWorkflowRaw} statusFlowsRaw Status data from API.
 * @returns {IInvoiceStatusWorkflow} Status workflow.
 */

export const mapToWorkflowDecision = (statusFlowsRaw: IInvoiceWorkflowRaw): IInvoiceStatusWorkflow => {
  try {
    return {
      statusFlows: statusFlowsRaw.status_flows.map(statusRaw => mapToDetailedWorkflowValues(statusRaw))
    };
  } catch (err) {
    throw new Error('Invoices API JSON adapter failed.');
  }
};

/**
 * Invoices Summary Adapter
 *
 * @param {IInvoicesSummaryRaw} summaryRaw Summary data from API.
 * @returns {IInvoicesSummary} Invoices Summary model.
 */
export const mapToInvoicesSummary = (summaryRaw: IInvoicesSummaryRaw): IInvoicesSummary => {
  try {
    return {
      total: summaryRaw.invoice_count,

      totalPendingReview: summaryRaw.invoice_statuses.pending_review,
      totalReadyToSubmit: summaryRaw.invoice_statuses.ready_to_submit,
      totalPendingApproval: summaryRaw.invoice_statuses.pending_approval,

      totalAge1to5: summaryRaw.invoice_aging.less_than_five,
      totalAge6to10: summaryRaw.invoice_aging.between_five_and_ten,
      totalAge11to15: summaryRaw.invoice_aging.between_ten_and_fifteen,
      totalAge16plus: summaryRaw.invoice_aging.greater_than_fifteen,
      totalAgeUndefined: summaryRaw.invoice_aging.undefined,

      totalPastDue: summaryRaw.invoice_due_dates.past_due,
      totalDueInLessThan3Days: summaryRaw.invoice_due_dates.less_than_three,
      totalDueIn3To5Days: summaryRaw.invoice_due_dates.between_three_and_five,
      totalUndefined: summaryRaw.invoice_due_dates.undefined
    };
  } catch (err) {
    throw new Error('Invoices Summary API JSON adapter failed.');
  }
};

/**
 * Invoice Log Adapter
 *
 * @param {IInvoiceLogRaw} invoiceLog Invoice Log data from API.
 * @returns {IInvoiceLog} Invoice Log model.
 */

const mapToInvoiceLog = (invoiceLog: IInvoiceLogRaw): IInvoiceLog => {
  try {
    return {
      action: invoiceLog.action,
      updatedAt: new Date(invoiceLog.invoice_updated_at),
      userName: invoiceLog.user,
      comment: invoiceLog.comment,
      rejectionReason: invoiceLog.rejection_reason
    };
  } catch (err) {
    throw new Error('Invoice Log API JSON adapter failed.');
  }
};

/**
 * Invoice Logs Adapter
 *
 * @param {IInvoiceLogsRaw} invoiceLogsRaw DInvoice Logs data from API.
 * @returns {IInvoiceLog[]} List of Invoices Log model.
 */

export const mapToInvoiceLogs = (invoiceLogsRaw: IInvoiceLogsRaw): IInvoiceLog[] => {
  try {
    const invoiceLogs = invoiceLogsRaw.user_actions.map(invoiceLogRaw => mapToInvoiceLog(invoiceLogRaw));
    invoiceLogs.sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
    return invoiceLogs;
  } catch (error) {
    throw new Error('Invoice Logs API JSON adapter failed.');
  }
};

////////////////////////////////////////////////////////////////////////////////
// FROM MODEL TO API
////////////////////////////////////////////////////////////////////////////////

/**
 * Detailed Values Raw Adapters
 */
const mapToDetailedValueBaseRaw = (detailedValue: DetailedValue): IDetailedValueRaw => {
  return {
    confidence: detailedValue.confidence,
    edited_by_user: detailedValue.edited
  };
};

const mapToDetailedStringValueRaw = (detailedValue: DetailedStringValue): IDetailedStringValueRaw => {
  if (!detailedValue?.value) return {};
  return {
    ...mapToDetailedValueBaseRaw(detailedValue),
    attribute_value: detailedValue.value
  };
};

const mapToDetailedNumberValueRaw = (detailedValue: DetailedNumberValue): IDetailedNumberValueRaw => {
  if (!detailedValue?.value) return {};
  return {
    ...mapToDetailedValueBaseRaw(detailedValue),
    attribute_value: detailedValue.value
  };
};

const mapToDetailedDateValueRaw = (detailedValue: DetailedDateValue): IDetailedStringValueRaw => {
  if (!detailedValue?.value) return {};
  return {
    ...mapToDetailedValueBaseRaw(detailedValue),
    attribute_value: detailedValue.value.toISOString().replace('Z', '')
  };
};

// const mapToDetailedPriorityValueRaw = (detailedValue: DetailedPriorityValue): IDetailedStringValueRaw => {
//   if (!detailedValue?.value) return {};
//   return {
//     ...mapToDetailedValueBaseRaw(detailedValue),
//     attribute_value: detailedValue.value
//   };
// };

const mapToDetailedAddressValueRaw = (detailedValue: DetailedAddressValue): IDetailedAddressValueRaw => {
  if (!detailedValue?.value) return {};
  const address = detailedValue.value;
  return {
    ...mapToDetailedValueBaseRaw(detailedValue),
    attribute_value: {
      value: address.address,
      normalizedValue: address.normalizedAddress,
      address_details: {
        region_code: address.addressDetails?.regionCode,
        language_code: address.addressDetails?.languageCode,
        postal_code: address.addressDetails?.postalCode,
        administrative_area: address.addressDetails?.administrativeArea,
        locality: address.addressDetails?.locality,
        address_lines: address.addressDetails?.addressLines?.map(line => line)
      }
    }
  };
};

const mapToInvoiceExceptionsRaw = (exceptionsValues: IInvoiceExceptions): IInvoiceExceptionsRaw => {
  return {
    total_exceptions: exceptionsValues.totalExceptions,
    total_item_exceptions: exceptionsValues.totalItemExceptions
  };
};

/**
 * Item Raw Adapter
 *
 * @param {Item} item Item model.
 * @returns {IItemRaw} Item data to the API.
 */
const mapToItemRaw = (item: Item): IItemRaw => {
  return {
    item_number: item.itemNo,
    description: mapToDetailedStringValueRaw(item.description),
    quantity: mapToDetailedNumberValueRaw(item.quantity),
    unit_price: mapToDetailedNumberValueRaw(item.unitPrice),
    amount: mapToDetailedNumberValueRaw(item.amount),
    gl_account: mapToDetailedStringValueRaw(item.glAccount),
    exception_count: item.exceptionCount
  };
};

/**
 * Invoice Raw Adapter
 *
 * @param {Invoice} invoice Invoice model.
 * @returns {IInvoiceRaw} Invoice data to the API.
 */
export const mapToInvoiceRaw = (invoice: Invoice): IInvoiceRaw => {
  try {
    return {
      document_no: invoice.documentNo,
      invoice_no: mapToDetailedStringValueRaw(invoice.invoiceNo),
      doc_date: invoice.documentDate.toISOString().replace('Z', ''),
      invoice_date: mapToDetailedDateValueRaw(invoice.invoiceDate),
      due_date: mapToDetailedDateValueRaw(invoice.dueDate),
      business_unit: mapToDetailedStringValueRaw(invoice.businessUnit),
      supplier: mapToDetailedStringValueRaw(invoice.supplier),
      supplier_id: mapToDetailedStringValueRaw(invoice.supplierId),
      gross_amt: mapToDetailedNumberValueRaw(invoice.grossAmount),
      net_amt: mapToDetailedNumberValueRaw(invoice.netAmount),
      total_tax_amount: mapToDetailedNumberValueRaw(invoice.totalTaxAmount),
      currency: mapToDetailedStringValueRaw(invoice.currency),
      pymt_terms: mapToDetailedStringValueRaw(invoice.paymentTerms),
      submitter: mapToDetailedStringValueRaw(invoice.submitter),
      requestor: mapToDetailedStringValueRaw(invoice.requester),
      invoice_status: invoice.status,
      invoice_priority: mapToDetailedStringValueRaw(invoice.priority),
      type: invoice.type,
      file_type: invoice.fileType,
      is_potentially_duplicated: invoice.isPotentiallyDuplicated,
      potentially_duplicate_of_document_no: invoice.potentiallyDuplicateOfDocumentNo
    };
  } catch (err) {
    throw new Error('Invoice adapter failed.');
  }
};

/**
 * Invoice Details Raw Adapter
 *
 * @param {InvoiceDetails} invoiceDetails Invoice with details model.
 * @returns {IInvoiceDetailsRaw} Invoice with details data to the API.
 */
export const mapToInvoiceDetailsRaw = (invoiceDetails: InvoiceDetails): IInvoiceDetailsRaw => {
  try {
    return {
      ...mapToInvoiceRaw(invoiceDetails),
      supplier_address: mapToDetailedAddressValueRaw(invoiceDetails.supplierAddress),
      items: invoiceDetails.items.map(item => mapToItemRaw(item)),
      exceptions: mapToInvoiceExceptionsRaw(invoiceDetails.exceptions)
    };
  } catch (err) {
    throw new Error('Invoice Details adapter failed.');
  }
};

/**
 * Invoice Raw Map
 *
 * @param {keyof Invoice} invoiceKey Invoice key.
 * @return {string} Invoice raw key to the API.
 */
export const mapToInvoiceRawKeys = (invoiceKey: keyof Invoice): string => {
  const invoiceMap: Record<string, string> = {
    documentNo: 'document_no',
    invoiceNo: 'invoice_no',
    documentDate: 'doc_date',
    invoiceDate: 'invoice_date',
    businessUnit: 'business_unit',
    netAmount: 'net_amt',
    totalTaxAmount: 'total_tax_amount',
    currency: 'currency',
    paymentTerms: 'pymt_terms',
    submitter: 'submitter',
    requester: 'requestor',
    priority: 'invoice_priority',
    supplier: 'supplier',
    dueDate: 'due_date',
    supplierId: 'supplier_id',
    grossAmount: 'gross_amt',
    status: 'invoice_status',
    type: 'type'
  };
  return invoiceMap[invoiceKey];
};

/**
 * Invoice Details Raw Patch Adapter
 *
 * @param {IInvoicePatch} invoiceUpdated Invoice with only fields updated
 * @returns {IInvoicePatchRaw} Invoice with details data to the API.
 */
export const mapToInvoicePatchRaw = (invoiceUpdated: IInvoicePatch): IInvoicePatchRaw => {
  const INVOICE_FIELDS_TO_RAW = {
    documentNo: 'document_no',
    invoiceNo: 'invoice_no',
    documentDate: 'doc_date',
    invoiceDate: 'invoice_date',
    businessUnit: 'business_unit',
    netAmount: 'net_amt',
    totalTaxAmount: 'total_tax_amount',
    currency: 'currency',
    paymentTerms: 'pymt_terms',
    submitter: 'submitter',
    requester: 'requestor',
    priority: 'invoice_priority',
    supplier: 'supplier',
    dueDate: 'due_date',
    supplierId: 'supplier_id',
    grossAmount: 'gross_amt',
    status: 'invoice_status',
    type: 'type'
  };
  return transformValuesKeysToRaw(INVOICE_FIELDS_TO_RAW, invoiceUpdated) as IInvoicePatchRaw;
};

/**
 * Invoice Items Raw Patch Adapter
 */
export const mapToItemsPatchRaw = (itemsSendToPatch: IItemsSendPatch): IItemsSendPatchRaw => {
  let itemsRawUpdated: IItemPatchRaw[] = [];

  const ITEMS_FIELDS_TO_RAW = {
    itemNo: 'item_number',
    description: 'description',
    quantity: 'quantity',
    unitPrice: 'unit_price',
    amount: 'amount',
    glAccount: 'gl_account',
    exceptionCount: 'exception_count'
  };

  itemsRawUpdated = itemsSendToPatch.items.map(item => {
    return {
      item_number: item.itemNo,
      ...transformValuesKeysToRaw(ITEMS_FIELDS_TO_RAW, item)
    };
  });
  return { document_no: itemsSendToPatch.documentNo, items: [...itemsRawUpdated] };
};

////////////////////////////////////////////////////////////////////////////////
// FROM  WEB-STORAGE TO MODEL
////////////////////////////////////////////////////////////////////////////////

const mapToInvoiceSortingSt = (sortingRaw: string): InvoiceFieldSorting => {
  const [field, order] = sortingRaw.split(':', 2);
  return {
    field: field as keyof Invoice,
    order: order as Sorting
  };
};

/**
 * Invoices Page Adapter (Web Storage)
 *
 * @param {IInvoicesPageFilterRaw} filterRaw Filter data from the web storage.
 * @returns {IInvoicesPageFilter} Invoices Page Filter model.
 */
export const mapToPageFilterSt = (filterRaw: IInvoicesPageFilterRaw): IInvoicesPageFilter => {
  try {
    return {
      page: filterRaw.page,
      pageSize: filterRaw.pageSize,
      statuses: new Set<InvoiceStatus>(filterRaw.statuses.map(status => mapToInvoiceStatus(status))),
      sorting: new Set<InvoiceFieldSorting>(filterRaw.sorting.map(sorting => mapToInvoiceSortingSt(sorting))),
      searchText: filterRaw.searchText
    };
  } catch (err) {
    throw new Error('Invoice Page Filter from Web-Storage JSON adapter failed.');
  }
};

////////////////////////////////////////////////////////////////////////////////
// FROM MODEL TO WEB-STORAGE
////////////////////////////////////////////////////////////////////////////////

const mapToInvoiceSortingRawSt = (sorting: InvoiceFieldSorting): string => `${sorting.field}:${sorting.order}`;

/**
 * Invoices Page Raw Adapter (Web Storage)
 *
 * @param {IInvoicesPageFilter} filter Invoices Page Filter model.
 * @returns {IInvoicesPageFilterRaw} Filter data to the web storage.
 */
export const mapToPageFilterRawSt = (filter: IInvoicesPageFilter): IInvoicesPageFilterRaw => {
  try {
    return {
      page: filter.page,
      pageSize: filter.pageSize,
      statuses: [...filter.statuses],
      sorting: [...filter.sorting].map(sorting => mapToInvoiceSortingRawSt(sorting)),
      searchText: filter.searchText
    };
  } catch (err) {
    throw new Error('Invoice Page Filter adapter failed.');
  }
};
