import { JSX } from 'react';
import { useFilesLoadedContext } from './FilesLoadedContext';
import './ProgressText.css';

const ProgressText = (): JSX.Element => {
  const { error, progress, inProgress, isCanceled } = useFilesLoadedContext();

  if (isCanceled) return <span className="cancel-text">Canceled</span>;
  if (!inProgress && error) return <span className="error-text">Error</span>;
  return <div className="progress-text">{`${progress || 0}%`}</div>;
};

export default ProgressText;
