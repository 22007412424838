import { toast } from '@/components/ui';
import { BackdropBlur } from '@/components/ui/BackdropBlur/BackdropBlur';
import SpinnerAiops from '@/components/ui/SpinnerAiops/SpinnerAiops';
import { useAppDataContext, useInvoiceDetailsContext } from '@/context';
import { Invoice } from '@/model/invoice';
import { Form, useFormikContext } from 'formik';
import { JSX, useEffect, useState } from 'react';
import ActionButtons from './ActionButtons';
import DetailsFields from './DetailsFields';
import { IActionButtonsProps } from './types';
import { AccessConfiguration } from '@/constants/accessConfiguration.constants';

const DetailsForm = (): JSX.Element => {
  const {
    state: { showActions }
  } = useInvoiceDetailsContext();
  const {
    state: { appPermissions },
    actions: { getUserAccessForResource }
  } = useAppDataContext();
  const { resetForm, validateForm, isValid, submitForm, isSubmitting } = useFormikContext<Invoice>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [ userAccess, setUserAccess ] = useState<Record<string, Record<string, boolean>>>({
    isEditDetailsForm: { write: false }
  });

  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      isEditDetailsForm: getUserAccessForResource(AccessConfiguration.pageComponents.edit_invoice_details)
    })
  }, [appPermissions]); 


  const handleSaveInvoice = (): Promise<void> =>
    submitForm()
      .then(() => setIsEdit(false))
      .then(() => toast('Updates saved successfully.', 'success'))
      .catch(() => toast('Updates not saved.', 'error'));

  const handleCancelSave = (): void => {
    resetForm();
    setIsEdit(false);
  };

  const editForm = (): void => {
    validateForm();
    setIsEdit(true);
  };

  const actionsProps: IActionButtonsProps = {
    isEdit,
    isValidForm: !isValid,
    onCancel: handleCancelSave,
    onEdit: editForm,
    onSubmit: () => {
      handleSaveInvoice();
    }
  };

  return (
    <Form
      className="relative flex flex-col justify-between rounded bg-[var(--color-secondary-background)] px-8 pb-[32.5px] pt-[29.5px]">
      {(showActions?.hasEditActions && userAccess.isEditDetailsForm?.write) && (<ActionButtons {...actionsProps} />)}
      <h3 className="pb-7 text-[18px] font-extrabold">Invoice Details</h3>
      <DetailsFields isEdit={isEdit} />
      {isSubmitting && <BackdropBlur children={<SpinnerAiops />} />}
    </Form>
  );
};

export default DetailsForm;
