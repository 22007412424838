import { DetailedNumberValue, DetailedStringValue } from '@/model/invoice';
import { currencyFormatter, dateFormatter } from '@/utils';

export const parseDetailValue = (
  invoiceValue: DetailedStringValue | DetailedNumberValue,
  name: string,
  isEdit: boolean
): string | number => {
  if (name === 'invoiceDate') return invoiceValue.value ? dateFormatter(new Date(invoiceValue.value)) : '';
  if (name === 'documentDate') return invoiceValue ? dateFormatter(new Date(invoiceValue as Date)) : '';
  if (name === 'totalTaxAmount' || name === 'netAmount') {
    if (isEdit) {
      return invoiceValue.value!;
    } else {
      return currencyFormatter(Number(invoiceValue.value));
    }
  }
  if (typeof invoiceValue === 'string') return invoiceValue;
  return invoiceValue.value || '';
};
