import CollapsibleCard from '@/components/ui/CollapsibleCard/CollapsibleCard';
import { useInvoiceDashboardContext } from '@/context';
import { JSX } from 'react';
import GraphInvoicesByAge from '../GraphInvoicesByAge/GraphInvoicesByAge';
import GraphInvoicesByStatus from '../GraphInvoicesByStatus';
import GraphInvoicesDueDate from '../GraphInvoicesDueDate/GraphInvoicesDueDate';

const CardInvoicesSummary = (): JSX.Element => {
  const {
    state: { isOpenInvoicesSummary, isLoadingInvoicesSummary, invoicesSummary },
    actions: { toggleOpenInvoicesSummary }
  } = useInvoiceDashboardContext();

  return (
    <CollapsibleCard
      title={'Invoice Analytics'}
      isOpen={isOpenInvoicesSummary}
      toggleOpen={toggleOpenInvoicesSummary}
      className="my-5"
    >
      <div>
        {isLoadingInvoicesSummary && (
          <div className="absolute -inset-0 flex h-full w-full items-center justify-center text-sm">Loading...</div>
        )}
        <div className={`mb-3 mt-6 flex flex-row justify-around ${isLoadingInvoicesSummary && 'invisible'}`}>
          <GraphInvoicesByStatus
            totalPendingReview={invoicesSummary.totalPendingReview}
            totalReadyToSubmit={invoicesSummary.totalReadyToSubmit}
            totalPendingApproval={invoicesSummary.totalPendingApproval}
          />
          <GraphInvoicesByAge
            totalAge1to5={invoicesSummary.totalAge1to5}
            totalAge6to10={invoicesSummary.totalAge6to10}
            totalAge11to15={invoicesSummary.totalAge11to15}
            totalAge16plus={invoicesSummary.totalAge16plus}
            totalAgeUndefined={invoicesSummary.totalAgeUndefined}
          />
          <GraphInvoicesDueDate
            totalPastDue={invoicesSummary.totalPastDue}
            totalDueInLessThan3Days={invoicesSummary.totalDueInLessThan3Days}
            totalDueIn3To5Days={invoicesSummary.totalDueIn3To5Days}
            totalUndefined={invoicesSummary.totalUndefined}
          />
        </div>
      </div>
    </CollapsibleCard>
  );
};

export default CardInvoicesSummary;
