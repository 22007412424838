import { JSX } from 'react';
import { ILogProps } from './types';

const Log = ({ title, data }: ILogProps): JSX.Element => {
  return (
    <div className="flex pb-2">
      <p className="pr-1 text-xs font-normal">{title}:</p>
      <p className="text-xs font-bold">{data}</p>
    </div>
  );
};

export default Log;
