import { JSX } from 'react';
import { ILabelDetailsProps } from './types';

const LabelDetails = (props: ILabelDetailsProps): JSX.Element => {
  const { isEdit, labelSubMsg, children, customClasses = '', ...rest } = props;
  const fontSize = isEdit ? 'font-bold ' : 'font-normal ';

  return (
    <div className="flex flex-col">
      <label {...rest} className={`min-w-max text-xs ${fontSize} ${customClasses}`}>
        {children}:&nbsp;
      </label>
      {isEdit && labelSubMsg && <p className="text-xs font-normal text-white">{labelSubMsg}</p>}
    </div>
  );
};

export default LabelDetails;
