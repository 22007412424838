import { toast } from '@/components/ui';
import { BackdropBlur } from '@/components/ui/BackdropBlur/BackdropBlur';
import SpinnerAiops from '@/components/ui/SpinnerAiops/SpinnerAiops';
import { useInvoiceDetailsContext } from '@/context';
import { Item } from '@/model/invoice';
import { ColDef, GetRowIdFunc, GetRowIdParams, GridSizeChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useFormikContext } from 'formik';
import { JSX, memo, useCallback, useMemo, useRef, useState } from 'react';
import ActionsTable from './ActionsTable';
import RenderDefaultCells from './RenderDefaultCells';
import RenderEditCells from './RenderEditCells';
import './TableItems.css';

const TableItems = (): JSX.Element => {
  const gridRef = useRef<AgGridReact<Item>>(null);
  const {
    resetForm,
    validateForm,
    isValid,
    values: itemsFormValues,
    submitForm,
    isSubmitting
  } = useFormikContext<Item[]>();
  const {
    state: { invoice }
  } = useInvoiceDetailsContext();
  const [filterRows, setFilterRows] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const exceptionsCount = invoice.exceptions?.totalItemExceptions || 0;
  const rowsWithWExceptions = itemsFormValues?.filter((item: Item) => item.exceptionCount > 0);
  const tableData = filterRows ? rowsWithWExceptions : itemsFormValues;

  const getRowId = useMemo<GetRowIdFunc>(() => (params: GetRowIdParams) => params.data.itemNo, []);
  const [columnDefs] = useState<ColDef[]>([
    {
      headerName: 'Line No.',
      field: 'itemNo',
      suppressSizeToFit: true,
      editable: false,
      maxWidth: 100
    },
    {
      headerName: 'Description',
      field: 'description',
      flex: 2,
      minWidth: 300
    },
    {
      headerName: 'Quantity',
      field: 'quantity',
      flex: 1
    },
    {
      headerName: 'Unit Price',
      field: 'unitPrice',
      flex: 1
    },
    {
      headerName: 'Net Line Amount',
      field: 'amount',
      flex: 1
    },
    {
      headerName: 'GL Account',
      field: 'glAccount',
      flex: 1
    }
  ]);

  const memoColumnsDef = useMemo(() => columnDefs, []);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      cellEditor: memo(RenderEditCells),
      cellRenderer: memo(RenderDefaultCells),
      wrapHeaderText: true,
      editable: true,
      suppressMovable: true
    }),
    []
  );

  const turnCellsEditMode = useCallback(() => {
    tableData.forEach((_: Item, inxCol: number) => {
      memoColumnsDef.forEach(({ field }) =>
        gridRef.current?.api.startEditingCell({
          rowIndex: inxCol,
          colKey: field!
        })
      );
    });
  }, []);

  const turnCellsGridMode = useCallback(() => gridRef.current?.api.stopEditing(), []);

  const handleSaveItems = (): void => {
    submitForm()
      .then(() => gridRef.current?.api.redrawRows())
      .then(() => {
        setFilterRows(false);
        setEditMode(false);
      })
      .then(() => toast('Updates saved successfully.', 'success'))
      .catch(() => toast('Updates not saved.', 'error'));
  };

  const startEditGrid = (): void => {
    turnCellsEditMode();
    validateForm();
    setEditMode(true);
  };

  const onEditCancel = (): void => {
    resetForm();
    turnCellsGridMode();
    setEditMode(false);
  };

  const propsActions = {
    startEditGrid,
    onEditCancel,
    onEditSave: handleSaveItems,
    isValid,
    exceptionsCount,
    setFilterRows,
    filterRows,
    editMode
  };

  return (
    <div className="ag-theme-s2pim s2pim-table-items">
      <ActionsTable {...propsActions} />
      <AgGridReact<Item>
        ref={gridRef}
        rowData={tableData}
        columnDefs={memoColumnsDef}
        defaultColDef={defaultColDef}
        animateRows={true}
        getRowId={getRowId}
        suppressPaginationPanel
        suppressCellFocus
        suppressRowClickSelection
        suppressClickEdit
        ensureDomOrder
        enableCellTextSelection
        rowHeight={69} // CSS variable --ag-row-height is not working as expected when adding spacing between rows.
        onGridSizeChanged={(event: GridSizeChangedEvent<Item>): void => event.api.sizeColumnsToFit()}
        domLayout="autoHeight"
      />
      {isSubmitting && <BackdropBlur children={<SpinnerAiops />} />}
    </div>
  );
};

export default TableItems;
