import { useAppDataContext, useInvoiceDetailsContext } from '@/context';
import { Item } from '@/model/invoice';
import { updateInvoiceItems } from '@/services';
import { IItemPatch, IItemsSendPatch } from '@/services/invoice.types';
import { Formik } from 'formik';
import { JSX } from 'react';
import { array, object } from 'yup';
import { ITEMS_VALIDATION_SCHEMA } from '../constants/invoice-validation';
import { getChangedValuesInvoiceOrItems } from '../utils/getChangedValuesInvoiceOrItems';
import TableItems from './TableItems';

const ViewItems = (): JSX.Element => {
  const {
    state: { invoiceItems, invoice },
    actions: { setInvoiceItems, setInvoice }
  } = useInvoiceDetailsContext();
  const validationSchema = array().of(object().shape({ ...ITEMS_VALIDATION_SCHEMA }));
  const {
    state: { user }
  } = useAppDataContext();

  const getItemsChangedValues = (initialItemsValues: Item[], itemsToCompareTo: Item[]): IItemPatch[] => {
    let itemsModifiedToPatch: IItemPatch[] = [];

    itemsModifiedToPatch = initialItemsValues.map((initialItem, inxInitialValue) => {
      let newItem: IItemPatch = { itemNo: initialItem.itemNo };
      itemsToCompareTo.forEach((itemsWithChanges, inxCompareTo) => {
        if (inxInitialValue === inxCompareTo) {
          const itemValuesChanged = getChangedValuesInvoiceOrItems(initialItem, itemsWithChanges);
          newItem = { ...newItem, ...itemValuesChanged };
        }
      });
      return newItem;
    });
    return itemsModifiedToPatch;
  };

  const handleSubmit = async (newInvoiceItems: Item[], resetForm: () => void): Promise<void> => {
    const itemsValuesToPatch: IItemsSendPatch = {
      documentNo: invoice.documentNo,
      items: [...getItemsChangedValues(invoice.items, newInvoiceItems)]
    };

    const [updateSuccess, invoiceDetailsUpdated] = await updateInvoiceItems(itemsValuesToPatch, user.name);
    if (updateSuccess) {
      setInvoiceItems(() => [...invoiceDetailsUpdated.items]);
      setInvoice(invoiceDetailsUpdated);
    } else {
      resetForm();
    }
  };

  return (
    <div className="relative mt-1 h-full w-auto flex-1">
      <Formik
        initialValues={invoiceItems}
        validationSchema={validationSchema}
        onSubmit={(invoiceItems, { resetForm }): Promise<void> => handleSubmit(invoiceItems, resetForm)}
        validateOnMount
        enableReinitialize
      >
        {(): JSX.Element => <TableItems />}
      </Formik>
    </div>
  );
};

export default ViewItems;
