import { WorkflowConfirmationModalConfig } from '@/components/modules/InvoiceDetails/constants/workflow-confirmation-modal-config.constants';
import { Comments, DialogModal, DropDown } from '@/components/ui';
import { DialogModalProps } from '@/components/ui/DialogModal/types';
import { useAppDataContext, useInvoiceDetailsContext } from '@/context';
import { IInvoiceDropdowns, IInvoiceWorkflowValues } from '@/model/invoice';
import { putInvoiceWorkflowDecision } from '@/services';
import { IInvoiceWorkflowDecisionRaw } from '@/services/invoice.types';
import { JSX, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FooterActions from './FooterActions';
// @ts-ignore
import { StickyAppFooter, useModal , Button} from "@aiops/styleguide";

const Footer = (): JSX.Element => {
  const navigateToUrl = useNavigate();
  const {
    state: { invoiceStatus, invoice, invoiceIsReady, showActions, dropDowns }
  } = useInvoiceDetailsContext();
  const navigateTo = (): void => navigateToUrl('/dashboard');
  const {
    state: { user }
  } = useAppDataContext();
  const userName = user.name;
  const documentNo = invoice.documentNo;
  const invoiceNo = invoice.invoiceNo?.value;
  const proceedWorkflow = invoiceStatus && invoiceStatus[0];
  const dontProceedWorkflow = invoiceStatus && invoiceStatus[1];

  const [modalConfig, setModalConfig] = useState<DialogModalProps>({ open: false });
  const setOpenModal = (modalOpen: boolean) =>
    setModalConfig(prev => {
      return { ...prev, ...{ open: modalOpen } };
    });
  const setConfirmEnable = (confirmEnable: boolean) =>
    setModalConfig(prev => {
      return { ...prev, ...{ confirmEnable } };
    });

  const commentRef = useRef<HTMLTextAreaElement>(null);
  const reasonRef = useRef<HTMLInputElement>(null);
  const [modalType, setModalType] = useState<string>('');
  const { openModal, closeModal } = useModal();

  const getDropDown = (type: any): any[] => {
    return dropDowns.filter((dropDown: IInvoiceDropdowns) => dropDown.field === type);
  };

  const handleWorkFlow = async (
    workFlow: IInvoiceWorkflowValues | undefined,
    userComment?: string | undefined,
    reason?: string
  ) => {
    const newWorkflowStatus: IInvoiceWorkflowDecisionRaw = {
      action: workFlow ? workFlow.action : '',
      ...((workFlow?.action === 'Submit' || workFlow?.action === 'Reject') && userComment && { comment: userComment }),
      ...(workFlow?.action === 'Reject' && reason && { rejection_reason: reason })
    };
    const [updateSuccess, _msg] = await putInvoiceWorkflowDecision(documentNo, newWorkflowStatus, userName);
    const isPendingApproval = workFlow?.result === 'Pending Approval';
    if (updateSuccess) {
      navigateToUrl('/dashboard', {
        state: [
          {
            msg: `Invoice No.${invoiceNo} has been ${isPendingApproval ? 'submitted for approval' : `successfully ${workFlow?.result}`
              }`,
            variant: 'success'
          }
        ]
      });
    } else {
      const errorMessage = _msg.response.data.message || 'An error occurred, please resubmit';
      navigateToUrl('/dashboard', { state: [{ msg: errorMessage, variant: 'error' }] });
    }
    closeModal();
  };

  const onSubmitOrApproveFlow = async () => {
    await handleWorkFlow(proceedWorkflow, commentRef?.current?.value);
  };

  const onRejectFlow = async () => {
    await handleWorkFlow(dontProceedWorkflow, commentRef?.current?.value, reasonRef?.current?.value);
  };

  const customModalOptions: any = {
    Reject: { headlineText: `Reject invoice ${invoiceNo}`, onConfirm: onRejectFlow, confirmEnable: false },
    Approve: { headlineText: `Approve invoice ${invoiceNo}` }
  };

  const openConfirmationModal = (workFlow: IInvoiceWorkflowValues | undefined) => {
    if (workFlow) {
      setModalConfig({
        ...modalConfig,
        open: true,
        onClose: onCloseCancelFlow,
        onCancel: onCloseCancelFlow,
        onConfirm: () => {
          onSubmitOrApproveFlow();
        },
        ...WorkflowConfirmationModalConfig[workFlow.action],
        ...customModalOptions[workFlow.action]
      });
      setModalType(workFlow.action);
    }
  };

  const onCloseCancelFlow = () => {
    closeModal();
  };


  const onCancelFlow = async () => {
    if (dontProceedWorkflow?.action === 'Reject') {
      openConfirmationModal(dontProceedWorkflow);
    } else {
      await handleWorkFlow(dontProceedWorkflow);
    }
  };

  const onSaveFlow = async () => {
    if (proceedWorkflow?.action === 'Submit' || proceedWorkflow?.action === 'Approve') {
      openConfirmationModal(proceedWorkflow);
    } else {
      await handleWorkFlow(proceedWorkflow);
    }
  };

  const reasonSelected = (val: string) => {
    if (val.length) {
      setModalConfig({ ...modalConfig, confirmEnable: true ,open: true });
    }
  };

  const modalInputRender = () => {
    switch (modalType) {
      case 'Submit':
        return (
          <>
            <Comments {...{ commentRef }} />
          </>
        );
      case 'Reject':
        return (
          <>
            <DropDown
              {...{
                dropDownRef: reasonRef,
                optionList: getDropDown('rejection_reason')[0].values,
                placeHolder: 'Choose an option',
                valueSelected: reasonSelected
              }}
            />
            <Comments {...{ commentRef }} />
          </>
        );
      default:
        return;
    }
  };

  if (modalConfig.open) {
    openModal({
      children: <DialogModal {...modalConfig}>{modalInputRender()}</DialogModal>,
    });
    setModalConfig({ open: false });
  }

  return (
    <StickyAppFooter>
      <div className="flex items-center">
        <Button variant="outlined" onClick={navigateTo}>
          {'Back'}
        </Button>
      </div>
      <div className="flex items-center gap-2">
      {showActions?.hasWorkflowActions && (
          <FooterActions
            invoiceWorkflow={invoiceStatus}
            isDisabled={!invoiceIsReady}
            onCancel={onCancelFlow}
            onSave={onSaveFlow}
          />
        )}
      </div>
    </StickyAppFooter>
  );
};

export default Footer;
