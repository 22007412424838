export const VALIDATION = {
  alphanumeric: { value: /^[A-z0-9\s!@#$&()\\`.+,/\"%*-:]*$/, message: 'Must be alphanumeric' },
  numeric: { value: /^\d*$/, message: 'Must be a number' },
  currency: { value: /^[0-9]+(\.\d{1,2})?$/, message: 'Must be a currency number' },
  genericRequired: { value: true, message: 'Field is required' },
  validPaymentTerms: [
    'Net 30',
    'Net 60',
    'Net 90',
    'Due Upon Receipt',
    'NET 30',
    'NET 30',
    'NET 60',
    'NET 90',
    'Other'
  ], // TODO: Remove hardcoded values
  validCurrencies: ['USD', 'EUR', 'JPY', 'GBP', 'AUD', 'CAD', 'CHF', 'CNH', 'Other'] // TODO: Remove hardcoded values
};
