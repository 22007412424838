import { InlineBadge, Tooltip } from '@/components/ui';
import { DetailedDateValue, DetailedNumberValue, DetailedStringValue } from '@/model/invoice';
import { isValidNumber } from '@/utils';
import { JSX } from 'react';

const ParsedContent = (
  invoiceValues: DetailedStringValue | DetailedNumberValue | DetailedDateValue
): JSX.Element | false => {
  if (!invoiceValues) return false;
  if (
    invoiceValues.value !== null &&
    invoiceValues.value !== undefined &&
    ((typeof invoiceValues.value === 'number' && isValidNumber(invoiceValues.value)) ||
      (typeof invoiceValues.value === 'string' && invoiceValues.value !== '') ||
      Object.prototype.toString.call(invoiceValues.value) === '[object Date]')
  )
    return false;

  return (
    <Tooltip content="Unknown Value" variant="error">
      <div className="ml-1 pl-0.5">
        <InlineBadge variant="error" />
      </div>
    </Tooltip>
  );
};
export default ParsedContent;
