import ViewDetail from '@/components/modules/InvoiceDetails/ViewDetail';
import ViewItems from '@/components/modules/InvoiceDetails/ViewItems';
import { InvoiceDetailsProvider, useAppDataContext } from '@/context';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { JSX, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Footer from '../Footer';
import InvoiceHeader from '../InvoiceHeader';
import ViewLogs from '../ViewLogs/ViewLogs';
import { AccessConfiguration } from '@/constants/accessConfiguration.constants';
import './InvoiceDetails.css';
// @ts-ignore
import { TabView } from '@aiops/styleguide';

const InvoiceDetails = (): JSX.Element => {
  const queryClientInvoiceDetail = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false
      }
    }
  });

  const {
    state: { appPermissions },
    actions: { getUserAccessForResource }
  } = useAppDataContext();

  const tabConfig = {
    routes: [
      {
        component: <ViewDetail />,
        view: 'Details',
        order: 0
      },
      {
        component: <ViewItems />,
        view: 'Items',
        order: 1
      },
      {
        component: <ViewLogs />,
        view: 'History',
        order: 2
      }
    ],
    defaultSelected: 0
  };

  const [userAccess, setUserAccess] = useState<Record<string, Record<string, boolean>>>({
    accessDetailsPage: { access: false }
  });

  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      accessDetailsPage: getUserAccessForResource(AccessConfiguration.page.details)
    });
  }, [appPermissions]);

  return (
    <>
      {userAccess.accessDetailsPage?.access && (
        <QueryClientProvider client={queryClientInvoiceDetail}>
          <InvoiceDetailsProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="page_content s2pim-invoice-details">
                <div>
                  <InvoiceHeader />
                  <div className="tab-view-container">
                    <div className="pt-[69px]">
                      <TabView config={tabConfig}></TabView>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </LocalizationProvider>
          </InvoiceDetailsProvider>
        </QueryClientProvider>
      )}
    </>
  );
};

export default InvoiceDetails;
