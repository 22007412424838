import { ICellEditorParams } from 'ag-grid-community';
import { FieldProps, getIn } from 'formik';
import { JSX, useState } from 'react';
import './ViewItems.css';

const FIELDS_WITH_MAX_LENGTH = ['quantity', 'unitPrice', 'amount'];
const MAX_LENGTH = 15;

const InputTable = ({ value, rowIndex, colDef, form, field }: FieldProps & ICellEditorParams): JSX.Element => {
  const { errors, handleBlur, setFieldValue } = form;
  const { name } = field;
  const [receivedValue, setReceivedValue] = useState(value);

  const customChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReceivedValue(e.target.value);
    setFieldValue(`[${rowIndex}].${name}`, e.target.value);
  };

  return (
    <div className={`input-items-table`}>
      <input
        className="controlled-input relative rounded border border-[var(--color-secondary-sadlilac)] bg-transparent text-xs outline-none"
        type="text"
        id={`${colDef.field}.value`}
        name={colDef.field}
        value={receivedValue}
        aria-invalid={getIn(errors[rowIndex], name) ? true : false}
        onChange={customChange}
        onBlur={handleBlur}
        maxLength={FIELDS_WITH_MAX_LENGTH.some(f => f === colDef.field) ? MAX_LENGTH : undefined}
      />
      {getIn(errors[rowIndex], name) && <div className="validation-error">{getIn(errors[rowIndex], name)}</div>}
    </div>
  );
};

export default InputTable;
