import { TOOLTIP_TIMEOUT } from '@/config/ui.config';
import { default as MuiTooltip } from '@mui/material/Tooltip';
import { JSX } from 'react';
import './Tooltip.css';
import { ITooltipProps } from './types';

const Tooltip = ({
  children,
  content,
  variant = 'default',
  placement = 'top-start',
  leaveDelay = TOOLTIP_TIMEOUT
}: ITooltipProps): JSX.Element => {
  return (
    <MuiTooltip
      title={content}
      arrow
      classes={{ popper: `s2pim-tooltip s2pim-tooltip--${variant}` }}
      placement={placement}
      leaveDelay={leaveDelay}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
