import { JSX, useMemo } from 'react';
import './FileLoadedProgress.css';
import FilesLoadedContext from './FilesLoadedContext';
import ProgressBar from './ProgressBar';
import ProgressIcon from './ProgressIcon';
import ProgressText from './ProgressText';
import { IFile } from './types';

interface IFileInfo {
  fileInfo: IFile;
}

const FileLoadedProgress = ({ fileInfo }: IFileInfo): JSX.Element => {
  const { name, error, isCanceled, progress, response } = fileInfo;

  const cancelFileUpload = (fileAbortControl: AbortController | undefined): void => {
    fileAbortControl?.abort();
  };

  const FILE_INFO_PROGRESS = useMemo(() => ({ ...fileInfo, cancelFileUpload: cancelFileUpload }), [progress, response]);

  return (
    <FilesLoadedContext.Provider value={FILE_INFO_PROGRESS}>
      <div className="s2pim-file-loaded-progress">
        <div className="w-1/5 truncate">{name}</div>
        <div className="progress-bar-container">
          <ProgressBar />
        </div>
        <div
          data-testid="progress-status-img"
          className={`s2pim-status-info w-1/5 ${isCanceled && error ? 's2pim-center-status-info' : ''}`}
        >
          <ProgressText />
          <ProgressIcon />
        </div>
      </div>
    </FilesLoadedContext.Provider>
  );
};

export default FileLoadedProgress;
