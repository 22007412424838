import IconCheck from '@/assets/icons/icon-check.svg';
import IconCancel from '@/assets/icons/icon-close-x.svg';
import IconEdit from '@/assets/icons/icon-edit.svg';
import { JSX } from 'react';
import Button from '../Elements/Button';
import { IActionButtonsProps } from './types';

const ActionButtons = ({ onEdit, onCancel, onSubmit, isEdit, isValidForm }: IActionButtonsProps): JSX.Element => {
  return (
    <div className="absolute right-[13.98px] top-[13.98px] flex gap-1">
      {isEdit ? (
        <>
          <Button value="Cancel" onClick={onCancel} icon={IconCancel} />
          <Button value="Save" onClick={onSubmit} icon={IconCheck} disabled={isValidForm} />
        </>
      ) : (
        <Button icon={IconEdit} onClick={onEdit} value="Edit Items" />
      )}
    </div>
  );
};

export default ActionButtons;
