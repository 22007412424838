import IconCheck from '@/assets/icons/icon-check.svg';
import IconClose from '@/assets/icons/icon-close.svg';
import { JSX } from 'react';
import { useFilesLoadedContext } from './FilesLoadedContext';
import './ProgressIcon.css';
//@ts-ignore
import { Button } from "@aiops/styleguide";

const ProgressIcon = (): JSX.Element => {
  const { error, inProgress, isCanceled, name, cancelFileUpload, abortControl } = useFilesLoadedContext();
  const imgSrc = error === true || error === undefined ? IconClose : IconCheck;
  const imgBackground = error === true || error === undefined ? 'progress-error' : 'progress-completed';

  if (inProgress)
    return (
      <Button
        className="files-uploaded-status progress cursor-pointer bg-transparent"
        onClick={(): void => cancelFileUpload(abortControl)}
      >
        <img src={IconClose} alt="in progress" className="h-4 w-4" />
      </Button>
    );

  if (isCanceled) return <></>;
  return (
    <div className={`files-uploaded-status ${imgBackground}`}>
      <img src={imgSrc} alt={`file-${name}`} className="h-4 w-4" />
    </div>
  );
};

export default ProgressIcon;
