import IconArrowDown from '@/assets/icons/icon-arrow-down.svg';
import IconArrowUp from '@/assets/icons/icon-arrow-up.svg';
import { FormControl, MenuItem, Select, SelectProps } from '@mui/material';
import { JSX, RefObject, useState } from 'react';
import './DropDown.css';
//@ts-ignore
import { Typography } from "@aiops/styleguide";

export type IDropDown = {
  dropDownRef?: RefObject<HTMLInputElement>;
  optionList?: string[];
  placeHolder?: string;
  valueSelected?: (val: string) => void;
};

const ToggleIcon = (props: SelectProps): JSX.Element => {
  const isOpen = props.className?.indexOf('MuiSelect-iconOpen') !== -1;
  return <>{isOpen ? <img src={IconArrowUp} alt="open" /> : <img src={IconArrowDown} alt="close" />}</>;
};

const DropDown = ({ dropDownRef, optionList, placeHolder, valueSelected }: IDropDown): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<string>('');

  const changeValue = (e: any): void => {
    setSelectedValue(e.target!.value);
    if (valueSelected) {
      valueSelected(e.target!.value);
    }
  };

  return (
    <div className="s2pim-drop-down-select">
      <Typography className="dropdown-label" variant="subheading1">
        <b>Reason</b>
      </Typography>
      {optionList && (
        <FormControl>
          <label id={`demo-simple-select-label`}>{selectedValue === '' && placeHolder}</label>
          <Select
            labelId={`demo-simple-select-label`}
            IconComponent={ToggleIcon}
            defaultValue={''}
            value={selectedValue}
            onChange={changeValue}
            inputProps={{ inputRef: dropDownRef }}
          >
            {optionList?.map((option: string) => (
              <MenuItem key={`${option}`} value={option} className="s2pim-dropdown-item">
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default DropDown;
