import { useEffect, useState } from 'react';

// A prefix to identify session and local storage keys saved using the storage hooks in this application.
const STORAGE_KEYS_PREFIX = 'aiops-s2pim_';

/**
 * Stores data in local or session storage.
 *
 * @param storage The specific storage to use localStorage / sessionStorage.
 * @param storageKey A string to identify the value being being cached.
 * @param fallbackState The default value when no value has been stored yet.
 * @returns A stateful value, and a function to update it.
 * @example
 * const [sessionStorage, collapsed, setCollapsed] = useLocalStorage('isSidebarCollapsed', false);
 */
const useStorage = <T>(
  storage: Storage,
  storageKey: string,
  fallbackState: T,
  mapperToStorage?: (obj: T) => any,
  mapperFromStorage?: (obj: any) => T
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  if (!storageKey) throw new Error(`"storageKey" is required and cannot be empty.`);

  const key = STORAGE_KEYS_PREFIX + storageKey;
  const storedString = storage.getItem(key);
  const parsedObject = storedString !== null ? JSON.parse(storedString) : null;
  const mappedObject = parsedObject && mapperFromStorage ? mapperFromStorage(parsedObject) : null;

  const [value, setValue] = useState<T>(mappedObject ?? parsedObject ?? fallbackState);

  useEffect(() => {
    const mappedValue = mapperToStorage ? mapperToStorage(value) : null;
    storage.setItem(key, JSON.stringify(mappedValue ?? value));
  }, [storage, value, key]);

  return [value, setValue];
};

export { useStorage };
