import { JSX } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  children?: JSX.Element;
}

export const BackdropBlur = ({ children }: IProps): JSX.Element => {
  const domElement = document.getElementById('root-nav-wrap') as HTMLDivElement;
  return ReactDOM.createPortal(
    <div className="s2pim-blur-layer absolute bottom-0 left-0 right-0 top-0 z-[2] flex min-h-screen min-w-full">
      <div className="s2pim-dark-layer m-auto rounded-2xl">{children}</div>
    </div>,
    domElement
  );
};
