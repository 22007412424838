import { JSX } from 'react';
import { IGraphInvoicesDueDateProps } from './types';

const GraphInvoicesDueDate = ({
  totalPastDue = 0,
  totalDueInLessThan3Days = 0,
  totalDueIn3To5Days = 0,
  totalUndefined = 0
}: IGraphInvoicesDueDateProps): JSX.Element => {
  return (
    <div className="flex flex-col">
      <h2 className="mb-1 text-sm font-bold">Approaching or Past Due Date</h2>

      <ul className="my-auto">
        <li className="flex h-10 items-center">
          <p
            data-testid="graph-invoices-age-past-due"
            className="text-lg font-extrabold text-[var(--color-dashboard-due-va)]"
          >
            {totalPastDue}
          </p>
          <p className="ml-2 text-xs text-[var(--color-secondary-sadlilac)]">Past Due</p>
        </li>
        <li className="flex h-10 items-center">
          <p
            data-testid="graph-invoices-age-due-less-than-3days"
            className="text-lg font-extrabold text-[var(--color-functional-negative-careful)]"
          >
            {totalDueInLessThan3Days}
          </p>
          <p className="ml-2 text-xs text-[var(--color-secondary-sadlilac)]">Due in &lt; 3 Days</p>
        </li>
        <li className="flex h-10 items-center">
          <p
            data-testid="graph-invoices-age-due-3to5-days"
            className="text-lg font-extrabold text-[var(--color-dashboard-due-vb)]"
          >
            {totalDueIn3To5Days}
          </p>
          <p className="ml-2 text-xs text-[var(--color-secondary-sadlilac)]">Due 3 - 5 Days</p>
        </li>
        {/* <li className="flex h-10 items-center">
          <p
            data-testid="graph-invoices-age-undefined"
            className="text-lg font-extrabold text-[var(---color-primary-hibiscus)]"
          >
            {totalUndefined}
          </p>
          <p className="ml-2 text-xs text-[var(--color-secondary-sadlilac)]">Undefined</p>
        </li> */}
      </ul>
    </div>
  );
};

export default GraphInvoicesDueDate;
