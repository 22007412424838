import { ChangeEvent, JSX, RefObject, useState } from 'react';
import './Comments.css';
//@ts-ignore
import { Typography } from "@aiops/styleguide";

type IComment = {
  commentRef: RefObject<HTMLTextAreaElement>;
};

const MAX_CHARS = 200;

const Comments = ({ commentRef }: IComment): JSX.Element => {
  const [comment, setComment] = useState<string>('');
  const [charsCount, setCharsCount] = useState<number>(0);

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
    const newText: string = event.target.value;

    if (newText.length <= MAX_CHARS) {
      setComment(newText);
      setCharsCount(newText.length);
    }
  };

  return (
    <div className="s2pim-comments-input-box">
      <Typography className="comments-label" variant="subheading1">
        <b>Comments</b> (Optional)
      </Typography>
      <div className="textarea-container">
        <textarea
          className="content"
          ref={commentRef}
          value={comment}
          placeholder="Leave a comment"
          onChange={handleTextChange}
        />
        <p className="comments-counter">{`${charsCount}/${MAX_CHARS}`}</p>
      </div>
    </div>
  );
};

export default Comments;
