import { array, boolean, number, object, string } from 'yup';
import { VALIDATION } from '@/components/modules/InvoiceDetails/constants/validation-constants';

const {
  validPaymentTerms: validPaymentTerms,
  validCurrencies,
  alphanumeric: { value: regExAlphaNumeric }
} = VALIDATION;

const VALIDATE_EDITED_AND_CONFIDENCE = {
  edited: boolean(),
  confidence: number()
};

export const ITEMS_VALIDATION_SCHEMA = {
  description: object().shape({
    value: string()
      .required('Enter Description')
      .matches(regExAlphaNumeric, 'Invalid values')
      .trim()
      .max(240, '240 character limit'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  quantity: object().shape({
    value: number()
      .required('Enter Quantity')
      .typeError('Must be a number')
      .min(1, 'Must be greater than 0')
      .test('len', '15 character limit', val => !!val && val.toString().length <= 15),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  unitPrice: object().shape({
    value: number()
      .required('Enter Unit Price')
      .typeError('Must be a number')
      .min(0.01, 'Must be greater than 0')
      .test('len', '15 character limit', val => !!val && val.toString().length <= 15),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  amount: object().shape({
    value: number()
      .required('Enter Amount')
      .typeError('Must be a number')
      .min(0.01, 'Must be greater than 0')
      .test('len', '15 character limit', val => !!val && val.toString().length <= 15),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  glAccount: object().shape({
    value: string()
      .required('Enter GL Account')
      .matches(regExAlphaNumeric, 'Invalid values')
      .trim()
      .max(15, '15 character limit'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  })
};

export const DETAILS_VALIDATION_SCHEMA = {
  documentNo: string() //
    .required('Enter Document Number')
    .typeError('Must be a string')
    .max(36, '36 character limit'),
  invoiceNo: object().shape({
    value: string() //
      .required('Enter Invoice Number')
      .typeError('Must be a string')
      .max(20, '20 character limit'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  supplierId: object().shape({
    value: string()
      .required('Enter Supplier ID')
      .matches(regExAlphaNumeric, 'Invalid values')
      .trim()
      .max(20, '20 character limit'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  requester: object().shape({
    value: string()
      .required('Enter Requester')
      .matches(regExAlphaNumeric, 'Invalid values')
      .trim()
      .max(40, '40 character limit'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  invoiceDate: object().shape({
    value: string() //
      .required('Enter Invoice Date')
      .typeError('Must be a string'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  paymentTerms: object().shape({
    value: string()
      .required('Select Payment Terms')
      .typeError('Must be a string')
      .oneOf(validPaymentTerms, 'Enter valid Payment Terms'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  businessUnit: object().shape({
    value: string() //
      .required('Enter Business Unit')
      .typeError('Must be a string')
      .max(20, '20 character limit'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  currency: object().shape({
    value: string()
      .required('Enter Currency')
      .typeError('Must be a string')
      .oneOf(validCurrencies, 'Enter valid Currency'),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  netAmount: object().shape({
    value: number()
      .required('Enter Net Amount')
      .typeError('Must be a number')
      .min(0.01, 'Must be greater than 0')
      .test('len', '15 character limit', val => !!val && val.toString().length <= 15),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  }),
  totalTaxAmount: object().shape({
    value: number()
      .notRequired()
      .typeError('Must be a number')
      .min(0, 'Must be greater than 0')
      .test('len', '15 character limit', val => (!val ? true : val.toString().length <= 15)),
    ...VALIDATE_EDITED_AND_CONFIDENCE
  })
};

export const INVOICE_VALIDATION_SCHEMA = object().shape({
  ...DETAILS_VALIDATION_SCHEMA,
  items: array().of(object().shape({ ...ITEMS_VALIDATION_SCHEMA }))
});
