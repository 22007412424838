import IconUpload from '@/assets/icons/icon-out.svg';
import IconReload from '@/assets/icons/icon-reload.svg';
import FileUpload from '@/components/modules/Common/FileUploads';
import DownloadInvoiceButton from '@/components/ui/DownloadInvoiceButton/DownloadInvoiceButton';
import { Button, Tooltip } from '@/components/ui';
import { useAppDataContext, useInvoiceDashboardContext } from '@/context';
import { DEFAULT_INVOICES_PAGE_FILTER } from '@/context/InvoiceDashboard/invoiceDashboard.context';
import { InvoiceStatus } from '@/model/invoice';
import { JSX, useEffect, useState } from 'react';
import './TableInvoiceActions.css';
import { AccessConfiguration } from '@/constants/accessConfiguration.constants';
// @ts-ignore
import { FilterBar, FilterButtonProps } from '@aiops/styleguide';

const TableInvoiceActions = (): JSX.Element => {
  const {
    state: { user, appPermissions },
    actions: { getUserAccessForResource }
  } = useAppDataContext();

  const [userAccess, setUserAccess] = useState<Record<string, Record<string, boolean>>>({
    uploadInvoice: { write: false }
  });

  /* on change in appPermissions for the user */
  useEffect(() => {
    setUserAccess({
      uploadInvoice: getUserAccessForResource(AccessConfiguration.pageComponents.dashboard_invoice_upload)
    });
  }, [appPermissions]);

  const {
    state: {
      isOpenInvoicesSummary,
      isLoadingInvoicesSummary,
      isLoadingInvoicesTable,
      invoicesSummary,
      filesUploaded,
      invoicesPageFilter
    },
    actions: { setFilesUploaded, updateDashboard, resetSorting, setInvoicesPageFilter }
  } = useInvoiceDashboardContext();

  const isLoading = isLoadingInvoicesSummary || isLoadingInvoicesTable;

  const filterByPendingReview = invoicesPageFilter.statuses.has(InvoiceStatus.PendingReview);
  const filterByReadyToSubmit = invoicesPageFilter.statuses.has(InvoiceStatus.ReadyToSubmit);
  const filterByPendingApproval = invoicesPageFilter.statuses.has(InvoiceStatus.PendingApproval);
  const filterByAll = invoicesPageFilter.statuses.size === 0;

  const filterButtons: FilterButtonProps = [
    {
      id: 'Pending Review',
      text: 'Pending Review',
      selected: filterByPendingReview,
      disabled: false
    },
    {
      id: 'Ready to Submit',
      text: 'Ready to Submit',
      selected: filterByReadyToSubmit,
      disabled: false
    },
    {
      id: 'Pending Approval',
      text: 'Pending Approval',
      selected: filterByPendingApproval,
      disabled: false
    }
  ];

  const onUploadComplete = (): (() => void) => {
    const timer = setTimeout(() => {
      updateDashboard();
    }, 8000); // TODO: Refactor this. there needs to be a backend call for this refactor.
    return () => clearTimeout(timer);
  };

  const handleClickRefresh = (): void => {
    if (!isLoading) {
      resetSorting();
    }
  };


  const handleStatusFilterChange = (newState: FilterButtonProps[]) => {
    if (!isLoading) {
      setInvoicesPageFilter({
        ...invoicesPageFilter,
        page: 1,
        pageSize: DEFAULT_INVOICES_PAGE_FILTER.pageSize,
        statuses: new Set(
          newState
            .filter(button => button.selected)
            .map(button => {
              switch (button.text) {
                case 'Pending Review':
                  return InvoiceStatus.PendingReview;
                case 'Ready to Submit':
                  return InvoiceStatus.ReadyToSubmit;
                case 'Pending Approval':
                  return InvoiceStatus.PendingApproval;
                default:
                  return InvoiceStatus.PendingReview;
              }
            })
        )
      });
    }
  };

  return (
    <div className="s2pim-table-invoice-actions mt-5 text-sm font-normal">
      <div className="flex justify-end">
        <div className="row gap-2">
          {userAccess.uploadInvoice?.write && (
            <FileUpload {...{ multiple: true, filesUploaded, setFilesUploaded, userName: user.name, onUploadComplete }}>
              <Tooltip content="Upload Invoices">
                <div className="s2pim-table-invoice-actions-btn flex h-[32px] w-[32px] items-center rounded-[4px] bg-[var(--color-secondary-darkberry-opacity50)] p-[5px] hover:cursor-pointer hover:bg-[var(--color-secondary-sadlilac)] hover:text-[var(--color-secondary-darkberry)]">
                  <img src={IconUpload} alt="Action" />
                </div>
              </Tooltip>
            </FileUpload>
          )}
          {/* <DownloadInvoiceButton /> */}
        </div>

        <div className="mx-2">
          <FilterBar filterButtons={filterButtons} onChange={handleStatusFilterChange} />
        </div>
        <Button
          icon={IconReload}
          borderVariant="square"
          options={isLoading ? 'icon-spinning' : undefined}
          onClick={handleClickRefresh}
          dataTestId="s2pim-table-invoices-reload-btn"
        />
      </div>
    </div>
  );
};

export default TableInvoiceActions;
