import 'ag-grid-community/styles/ag-grid.css';
import { JSX } from 'react';
import { AppProps } from 'single-spa';
import './assets/styles/ag-theme.css';
import './assets/styles/preflight.css';
import './assets/styles/reset.css';
import './assets/styles/tailwind.css';
import './assets/styles/theme.css';
import Router from './router';
import { AppDataProvider } from './context';
import { IAppDataProviderProps } from './context/AppData/appData.types';
import { CustomAppProps } from './model/platform';
// @ts-ignore
import { AppWrapper, AuthCheckWrap, AuthProvider, ModalProvider, ToastProvider } from '@aiops/styleguide';
// @ts-ignore
import { ChatbotWidget } from '@aiops/chatbot';

const Root = (props: AppProps & CustomAppProps): JSX.Element => {
  // Get appId and path from props passed by root config. Injected in the app data context (AppDataProvider).
  const { appId, appName, path, settingsPath, endpoints } = props?.app || {};

  // Check props
  if (!appId || !appName || !path || !settingsPath || !endpoints) {
    throw new Error(
      `Expected appId, appName, path, settingsPath, endpoints to be in props.app: ${JSON.stringify(props)}`
    );
  }

  const appDataProviderProps: IAppDataProviderProps = {
    appId,
    appName,
    appPath: path,
    appSettingsPath: settingsPath,
    apiEndpoints: endpoints
  };

  // Check authorization
  const checkPerms = (perms: Record<string, any>): boolean => {
    return !!perms?.apps[appId];
  };

  // Render child component
  const renderWithAuthCheck = (): JSX.Element => {
    return (
        <div className="s2pim">
          <AppDataProvider {...appDataProviderProps}>
            <Router />
          </AppDataProvider>
          <ChatbotWidget appId={appId}/>
        </div>
    );
  };

  return (
      <AppWrapper>
        <AuthProvider checkPermissions={checkPerms}>
          <ToastProvider>
            <ModalProvider>
              <AuthCheckWrap renderWithAuthCheck={renderWithAuthCheck} />
          </ModalProvider>
        </ToastProvider>
      </AuthProvider>
    </AppWrapper>
  );
};

export default Root;
