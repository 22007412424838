import { Tooltip } from '@/components/ui';
import { DetailedStringValue, Item } from '@/model/invoice';
import { currencyFormatter } from '@/utils';
import { ICellRendererParams } from 'ag-grid-community';
import { JSX } from 'react';
import ParsedConfidence from '../../Common/ParsedConfidence';
import ParsedContent from '../../Common/ParsedContent';

const tooltipText = (invoiceValue: DetailedStringValue): string => {
  if (!invoiceValue) return '';
  if (typeof invoiceValue === 'string') {
    return invoiceValue;
  } else {
    return invoiceValue.value!;
  }
};

const RenderDefaultCells = (props: ICellRendererParams<Item>): JSX.Element => {
  const { colDef, value } = props;
  switch (colDef!.field) {
    case 'itemNo':
      return <p className="truncate pr-1">{value}.</p>;

    case 'unitPrice':
    case 'amount': {
      return (
        <div className="flex items-center">
          <p className="truncate pr-1">{currencyFormatter(value?.value)}</p>
          {ParsedContent(value) || ParsedConfidence(value)}
        </div>
      );
    }

    case 'description':
      return (
        <div className="flex items-center">
          <Tooltip content={tooltipText(value)} variant="default" placement="top">
            <p className="truncate pr-1">{value?.value || '-'}</p>
          </Tooltip>
          {ParsedContent(value) || ParsedConfidence(value)}
        </div>
      );

    default:
      return (
        <div className="flex items-center">
          <p className="truncate pr-1">{value?.value || '-'}</p>
          {ParsedContent(value) || ParsedConfidence(value)}
        </div>
      );
  }
};

export default RenderDefaultCells;
