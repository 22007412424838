type StatusKey = 'Submit' | '';

export const WorkflowConfirmationModalConfig: { [index: StatusKey | string]: any } = {
  Submit: {
    headlineText: 'Submit invoice for approval',
    bodyText: `By submitting this invoice I confirm that the invoice details and line items are correct.`,
    cancelButtonText: 'Cancel',
    confirmationButtonText: 'Submit Invoice',
    variant: 'medium'
  },
  Reject: {
    headlineText: 'Reject Invoice',
    cancelButtonText: 'Cancel',
    confirmationButtonText: 'Reject Invoice',
    variant: 'medium'
  },
  Approve: {
    // customization of headlineText in Footer component.
    headlineText: 'Approve Invoice',
    bodyText: `Are you sure you want to approve this invoice? This action can not be undone.`,
    cancelButtonText: 'Cancel',
    confirmationButtonText: 'Approve Invoice'
    // default variant: small
  }
};
