import { useInvoiceDetailsContext } from '@/context';
import { Field } from 'formik';
import { JSX } from 'react';
import './DetailFields.css';
import { FIELDS_DATA } from '@/config/FieldsData.config';
import LabelDetails from './LabelDetails';
import ManagerFieldsRender from './ManagerFieldsRender';
import { IDetailsFieldsProps, IMapInputs } from './types';

const DetailsFields = ({ isEdit = false }: IDetailsFieldsProps): JSX.Element => {
  const classContainerFields = isEdit ? 's2pim-fields-container-edit' : 's2pim-fields-container-view';
  const {
    state: { dropDowns }
  } = useInvoiceDetailsContext();

  return (
    <div className="s2p-details-fields-container">
      {FIELDS_DATA.map(
        ({
          inputName,
          inputLabel,
          labelSubMsg,
          isEditable,
          inputPlaceholder,
          inputLabelEditMode,
          maxLength
        }: IMapInputs) => (
          <div key={`field-${inputName}-block`} className={classContainerFields}>
            <LabelDetails
              key={`field-${inputName}-label`}
              htmlFor={inputName}
              labelSubMsg={labelSubMsg}
              children={isEdit && inputLabelEditMode ? inputLabelEditMode : inputLabel}
              isEdit={isEdit}
              customClasses="label-item"
            />
            <Field
              component={ManagerFieldsRender}
              isEditable={isEditable}
              key={`field-${inputName}-detail`}
              name={inputName}
              isEdit={isEdit}
              inputPlaceholder={inputPlaceholder}
              dropDowns={dropDowns}
              maxLength={maxLength}
            />
          </div>
        )
      )}
    </div>
  );
};

export default DetailsFields;
