import IconCalendar from '@/assets/icons/icon-calendar.svg';
import { DetailedStringValue, InvoiceDetails } from '@/model/invoice';
import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FieldInputProps, FormikFormProps, FormikProps, FormikValues } from 'formik';
import { JSX, useState } from 'react';
import './DatePickerFormik.css';

/* TODO: Tech Debt:
 - Remove days from previous months or set a lither font or color for them.
 - Remove Formik dependency 
 - Refactor CSS
*/

const IconCalendarElement = (): JSX.Element => <img src={IconCalendar} alt="open-calendar" />;

const DatePickerFormik = ({
  value,
  setValues,
  values,
  name
}: FormikProps<InvoiceDetails> & FormikFormProps & FieldInputProps<FormikValues>): JSX.Element => {
  const [newDate, setNewDate] = useState<Date | null>(new Date(value?.value));

  const handleChange = (date: Date | null): void => {
    setNewDate(date);

    if (date) {
      setValues({
        ...values,
        [name]: {
          ...(values[name as keyof InvoiceDetails] as DetailedStringValue),
          value: date.toISOString()
        }
      });
    }
  };

  return (
    <div className="s2pim-datepicker">
      <DatePicker
        value={newDate}
        onChange={(value: Date | null, keyboardInputValue?: string | undefined): void => handleChange(value)}
        renderInput={(props: TextFieldProps): JSX.Element => <TextField {...props} />}
        showDaysOutsideCurrentMonth
        components={{
          OpenPickerIcon: IconCalendarElement
        }}
        PopperProps={{
          disablePortal: true
        }}
        ignoreInvalidInputs
      />
    </div>
  );
};

export default DatePickerFormik;
