import { ToastContainer } from '@/components/ui';
import { JSX } from 'react';
import { Outlet } from 'react-router-dom';
import './Layout.css';

const Layout = (): JSX.Element => {
  return (
      <>
        <ToastContainer/>
        <div className="full_page">
          <Outlet/>
        </div>
      </>
  );
};

export default Layout;
