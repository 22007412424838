//export const API_SERVER = 'xyz.execute-api.us-east-1.amazonaws.com';
//export const API_BASE = 'dev/s2p-invoice-management';

export const API_ENDPOINT_BASEURL_APPID = 's2p-invoice-management';

export const API_ENDPOINT_GET_INVOICES = '';
export const API_ENDPOINT_POST_INVOICE_UPLOAD = 'invoices';
export const API_ENDPOINT_PATCH_INVOICE = 'invoices';
export const API_ENDPOINT_GET_INVOICE_DASHBOARD = 'dashboard';
export const API_ENDPOINT_GET_INVOICE_WORKFLOW_STATUS = 'status';
export const API_ENDPOINT_PUT_INVOICE_WORKFLOW = 'workflow-decision';
export const API_ENDPOINT_GET_INVOICE_LOGS = 'audit';
export const API_ENDPOINT_GET_INVOICE_DOWNLOAD = 'invoice-file';
export const API_ENDPOINT_GET_INVOICE_DROPDOWNS = 'drop-downs';
export const API_ENDPOINT_GET_INVOICE_TEMPLATE = 'invoice-template';
