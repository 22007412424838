import { JSX } from 'react';
import { IHeaderDataProps } from './types';

const HeaderData = ({ title, value }: IHeaderDataProps): JSX.Element => {
  return (
    <div className="mr-9 flex min-w-[219px] flex-row">
      <div className="flex items-center justify-center">
        <div className="h-6 border-l border-[var(--color-secondary-sadlilac)]"></div>
      </div>
      <div className="flex flex-col items-start justify-center px-9">
        <p className="mb-1 text-[12px] font-bold">{title}</p>
        <p className="text-[14px] font-normal text-[var(--color-primary-hibiscus)]">{value || '-'}</p>
      </div>
    </div>
  );
};

export default HeaderData;
