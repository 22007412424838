import IconCollapse from '@/assets/icons/icon-collapse.svg';
import IconOpen from '@/assets/icons/icon-open.svg';
import { JSX, useState } from 'react';
import './CollapsibleCard.css';
import { ICollapsibleCardProps } from './types';

const CollapsibleCard = ({ title, isOpen, toggleOpen, className, children }: ICollapsibleCardProps): JSX.Element => {
  // const [isCardOpen, toggleCard] = useToggleSession(`collapsible-card_${storageId}`, isOpen);
  const [isCardOpen, setIsCardOpen] = useState<boolean>(isOpen);

  const handleClickToggle = (): void => {
    toggleOpen();
    setIsCardOpen(state => !state);
  };

  return (
    <div
      className={`s2pim-collapsible-card s2pim-card relative flex min-w-[950px] rounded-md border border-[var(--color-secondary-sadlilac)] p-4 ${className}`}
    >
      <div className="flex w-full flex-col">
        <button className="s2pim-collapsible-card--btn text-left" onClick={handleClickToggle}>
          <h2 className="text-[16px] font-extrabold">{title}</h2>
          <img src={isCardOpen ? IconCollapse : IconOpen} className="absolute right-3 top-3" alt="Toggle Card" />
        </button>
        {isCardOpen && <div>{children}</div>}
      </div>
    </div>
  );
};

export default CollapsibleCard;
