export const AccessConfiguration = {
    /* List all page resource type here */
    page: {
        dashboard: 'Dashboard Page',
        details: 'Details Page'
    },
    /* List all page component resource type here */
    pageComponents: {
        dashboard_invoice_upload: 'Dashboard Page_Upload Invoice',
        footer_discard_invoice: 'Details Footer_Discard Invoice',
        footer_submit_invoice: 'Details Footer_Submit Invoice',
        edit_invoice_details: 'Details Page_Edit Invoice',
        edit_table_actions: 'Items Page_Edit Invoice',
        footer_approve_invoice: 'Details Footer_Approve Invoice',
        footer_reject_invoice:'Details Footer_Reject Invoice'
    }
}