import { IMapInputs } from '../components/modules/InvoiceDetails/ViewDetail/types';

export const FIELDS_DATA: IMapInputs[] = [
  {
    isEditable: true,
    inputType: 'number',
    inputName: 'supplierId',
    inputLabel: 'Supplier ID',
    inputPlaceholder: 'Supplier ID',
    maxLength: 20
  },
  {
    isEditable: false,
    inputType: 'text',
    inputName: 'submitter',
    inputLabel: 'Submitter',
    inputPlaceholder: 'Submitter'
  },
  {
    isEditable: true,
    inputType: 'text',
    inputName: 'requester',
    inputLabel: 'Requester',
    inputPlaceholder: ''
  },
  {
    isEditable: false,
    inputType: 'number',
    inputName: 'documentNo',
    inputLabel: 'Record No.',
    inputPlaceholder: 'Record Number'
  },
  {
    isEditable: true,
    inputType: 'date',
    inputName: 'invoiceDate',
    inputLabel: 'Invoice Date',
    inputPlaceholder: 'Invoice Date'
  },
  {
    isEditable: false,
    inputType: 'date',
    inputName: 'documentDate',
    inputLabel: 'Record Date',
    inputPlaceholder: ''
  },
  {
    isEditable: true,
    inputType: 'text',
    inputName: 'invoiceNo',
    inputLabel: 'Invoice No.',
    inputPlaceholder: 'Invoice Number'
  },
  {
    isEditable: true,
    inputType: 'text',
    inputName: 'paymentTerms',
    inputLabel: 'Payment Terms',
    inputPlaceholder: 'Payment Terms'
  },
  {
    isEditable: true,
    inputType: 'text',
    inputName: 'priority',
    inputLabel: 'Payment Priority',
    inputPlaceholder: 'Payment Priority'
  },
  {
    isEditable: true,
    inputType: 'text',
    inputName: 'businessUnit',
    inputLabel: 'Business Unit',
    inputPlaceholder: 'Business Unit'
  },
  {
    isEditable: true,
    inputType: 'text',
    inputName: 'currency',
    inputLabel: 'Currency',
    inputPlaceholder: 'Currency'
  },
  {
    isEditable: true,
    labelSubMsg: '(Optional)',
    inputType: 'number',
    inputName: 'totalTaxAmount',
    inputLabel: 'Total tax',
    inputPlaceholder: 'Total Tax Amount',
    maxLength: 15
  },
  {
    isEditable: true,
    labelSubMsg: '(tax excl.)',
    inputType: 'number',
    inputName: 'netAmount',
    inputLabel: 'Net Amount (tax excl.)',
    inputPlaceholder: 'Net Amount',
    inputLabelEditMode: 'Net Amount',
    maxLength: 15
  }
];
